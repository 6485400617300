import { Building, Room } from '../../services/types'

const expand = (rooms?: Room[]): { name?: string; path?: string } => {
    let result
    if (rooms?.[0].rooms) {
        result = expand(rooms?.[0].rooms)
    }

    if (result) {
        result = {
            path: `${rooms?.[0].name} - ${result.path}`,
            name: result.name,
        }
    } else {
        result = {
            path: rooms?.[0].name,
            name: rooms?.[0].sensors?.[0]?.name,
        }
    }

    return result
}

export const extractInfo = (building?: Building) => {
    const info = expand(building?.rooms)

    return {
        path: `${building?.name} - ${info.path}`,
        name: `${info.name}`,
    }
}
