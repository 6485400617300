import { config } from '../../../config'
import { ResponsePromise, client } from '@unicaiot/unica-iot-gallery-core'

export interface IPbiEmbedService {
    checkEmbedWorkspaces: () => ResponsePromise<boolean>
}

export const pbiEmbedService: IPbiEmbedService = {
    checkEmbedWorkspaces() {
        return client.get<boolean>(config.pbiEmbed.worksapcesExist)
    },
}
