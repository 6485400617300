import {
    ErrorLayout,
    HeadingLevel,
    TenantsWithCo2PredictionsEnabledPreferenceKey,
    Title,
    useGetCombinedPreference,
    Widget,
} from '@unicaiot/unica-iot-gallery-airinsight'
import React, { Fragment, FunctionComponent, useCallback, useEffect, useMemo, useState } from 'react'
import { useGetBuilding, useGetData, useGetPredictions } from '../../services/hooks'
import '../../../../../node_modules/react-vis/dist/style.css'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
    PageError,
    useUser,
    useWindowDimensions,
} from '@unicaiot/unica-iot-gallery-core'
import styles from './GraphWidget.module.scss'
import { Graph } from '../Explorer/Graph'
import { isMobileOnly } from 'react-device-detect'
import { HData, Type } from '../../services/types'
import { extractInfo } from '../Helpers/SensorHelper'

export interface Props {
    id: string
    type: Type
    startDate: Date
    endDate: Date
    latest?: boolean
}

export const GraphWidget: FunctionComponent<Props> = ({ id, type, startDate, endDate, latest = true }) => {
    const [data, setData] = useState<{ x: number; y: number }[]>()
    const [predictions, setPredictions] = useState<{ x: number; y: number }[]>()

    const bResult = useGetBuilding(id)

    if (bResult?.error) {
        throw new RangeError(bResult.error)
    }

    const info = useMemo(() => {
        return extractInfo(bResult.data)
    }, [bResult.data])

    const result = useGetData(id, startDate, endDate)

    if (result?.error) {
        throw new PageError(result.error)
    }

    const user = useUser()
    const prResult = useGetCombinedPreference<string[]>(user?.sub, TenantsWithCo2PredictionsEnabledPreferenceKey)

    const pResult = useGetPredictions(
        user && prResult.data?.[TenantsWithCo2PredictionsEnabledPreferenceKey]?.includes(user.TenantId) ? id : undefined
    )

    const processData = useCallback(
        (
            data: HData | undefined,
            latest: boolean,
            set: (
                value: React.SetStateAction<
                    | {
                          x: number
                          y: number
                      }[]
                    | undefined
                >
            ) => void
        ) => {
            if (data) {
                let points = data?.rows
                    .map(r => {
                        return {
                            x: Date.parse(r.ts.substring(2).split(' ')[0]),
                            y: Math.round(Number.parseFloat(r.val.substring(2))),
                        }
                    })
                    .sort((a, b) => {
                        let comparison = 0
                        if (a.x > b.x) {
                            comparison = 1
                        } else if (a.x < b.x) {
                            comparison = -1
                        }
                        return comparison
                    })

                if (latest && points && points.length > 0) {
                    const start = points[points.length - 1].x - 60 * 60 * 1000
                    points = points.filter(e => e.x >= start)
                }

                set && set(points)
            }
        },
        []
    )

    useEffect(() => {
        processData(result.data, latest, setData)
    }, [result.data, latest, processData])

    useEffect(() => {
        processData(pResult.data, false, setPredictions)
    }, [pResult.data, processData])

    const wd = useWindowDimensions()

    const width = isMobileOnly ? wd.width - 2 * 1 * 8 : (wd.width - 8 * 13) * 0.5 - 2 * 5 * 8
    const height = isMobileOnly ? 200 : wd.height - 65 - 150 - 8 * 23 - 2 * 8 * 8

    return (
        <Widget className={styles.widget} borders={true}>
            {bResult.loading || result.loading || !data || pResult.loading || prResult.loading ? (
                <ActivityIndicator className={styles.loader} size={ActivityIndicatorEnumType.large} />
            ) : result.error || bResult.error ? (
                <ErrorLayout />
            ) : (
                <Fragment>
                    <Title className={styles.titlePlace} headingLevel={HeadingLevel.h1}>
                        {info.path}
                    </Title>
                    <Title className={styles.titleSensor} headingLevel={HeadingLevel.h1}>
                        {info.name}
                    </Title>
                    <div className={styles.chart}>
                        <Graph
                            data={data}
                            predictions={predictions?.filter(p => p.x >= data[data.length - 1]?.x)}
                            width={width}
                            height={height}
                            type={type}
                        />
                    </div>
                </Fragment>
            )}
        </Widget>
    )
}
