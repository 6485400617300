import React, { FunctionComponent, useState, useEffect, useMemo, useContext, Fragment } from 'react'
import styles from './Comparer.module.scss'
import { useGetDatas } from '../../services/hooks'
import '../../../../../node_modules/react-vis/dist/style.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../Common/datepicker.css'
import {
    PageError,
    ActivityIndicator,
    ActivityIndicatorEnumType,
    useWindowDimensions,
    LocaleContext,
} from '@unicaiot/unica-iot-gallery-core'
import { isMobileOnly } from 'react-device-detect'
import { Type } from '../../services/types'
import { CompareGraph } from '../Comparer/CompareGraph'
import { translations } from '../../../../translations'

export interface Props {
    sensors: { id: string; name: string }[]
    type: Type
}

export const Comparer: FunctionComponent<Props> = ({ sensors, type }) => {
    const locale = useContext(LocaleContext)

    const [data, setData] = useState<{ name: string; data: { x: number; y: number }[] }[]>()

    const addDays = (date: Date, days: number) => {
        date.setDate(date.getDate() + days)
        return date
    }

    const [startDate, setStartDate] = useState<Date>(addDays(new Date(), -1))
    const [endDate, setEndDate] = useState<Date>(new Date())

    const ids = useMemo<string[]>(() => {
        return sensors.map(sensor => sensor.id)
    }, [sensors])

    const dResult = useGetDatas(ids, startDate, endDate)

    if (dResult?.error) {
        throw new PageError(dResult.error)
    }

    useEffect(() => {
        if (dResult.data) {
            setData(
                dResult.data.map(item => {
                    return {
                        name: sensors.find(s => s.id === item.meta.id.substring(2))?.name || '',
                        data: item.rows
                            .map(r => {
                                return {
                                    x: Date.parse(r.ts.substring(2).split(' ')[0]),
                                    y: Number.parseFloat(r.val.substring(2)),
                                }
                            })
                            .sort((a, b) => {
                                let comparison = 0
                                if (a.x > b.x) {
                                    comparison = 1
                                } else if (a.x < b.x) {
                                    comparison = -1
                                }
                                return comparison
                            }),
                    }
                })
            )
        }
    }, [dResult.data, sensors])

    const wd = useWindowDimensions()

    const width = isMobileOnly ? wd.width - 2 * 1 * 8 : wd.width - 2 * 6 * 8
    const height = isMobileOnly ? wd.height - 65 - 1 * 5 * 8 - 26 * 8 : wd.height - 65 - 2 * 8 * 5 - 27 * 8

    return (
        <Fragment>
            <div className={styles.dpContainer}>
                <div className={styles.inputContainer}>
                    <span className={styles.label}>{locale._(translations.filters.periodLabel)}</span>
                    <DatePicker
                        className={styles.dp}
                        required={true}
                        selected={startDate}
                        onChange={(date: Date | null) => date && setStartDate(date)}
                        selectsStart={true}
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                        dateFormat="d MMMM yyyy"
                        wrapperClassName={styles.dpWrapper}
                    />
                    <span className={styles.label}>{locale._(translations.filters.tillLabel)}</span>
                    <DatePicker
                        className={styles.dp}
                        popperPlacement="bottom-end"
                        required={true}
                        selected={endDate}
                        onChange={(date: Date | null) => date && setEndDate(date)}
                        selectsEnd={true}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        maxDate={new Date()}
                        dateFormat="d MMMM yyyy"
                        wrapperClassName={styles.dpWrapper}
                    />
                </div>
            </div>
            <div className={styles.chartContainer}>
                {dResult.loading || !data ? (
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
                ) : (
                    <CompareGraph data={data} width={width} height={height} type={type} />
                )}
            </div>
        </Fragment>
    )
}
