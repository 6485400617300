import { PointKpi, Type } from '../../services/types'

const kpiRanges = new Map<Type, Map<PointKpi, number[][]>>()

/*Node: the format is [[higher], [lower]] */

const co2 = new Map<PointKpi, number[][]>()
co2.set(PointKpi.InSufficient, [[1200, 3000]])
co2.set(PointKpi.Moderate, [[800, 1200]])
co2.set(PointKpi.Sufficient, [[0, 800]])
kpiRanges.set(Type.CO2, co2)

const temperature = new Map<PointKpi, number[][]>()
temperature.set(PointKpi.InSufficient, [
    [24, 100],
    [0, 18],
])
temperature.set(PointKpi.Moderate, [
    [22, 24],
    [18, 20],
])
temperature.set(PointKpi.Sufficient, [
    [22, 21],
    [20, 21],
])
kpiRanges.set(Type.Temperature, temperature)

const humidity = new Map<PointKpi, number[][]>()
humidity.set(PointKpi.InSufficient, [
    [70, 100],
    [0, 30],
])
humidity.set(PointKpi.Moderate, [
    [60, 70],
    [30, 40],
])
humidity.set(PointKpi.Sufficient, [
    [60, 50],
    [40, 50],
])
kpiRanges.set(Type.Humidity, humidity)

export default kpiRanges
