import React, { FunctionComponent, useState, useEffect, useContext, useMemo, Fragment } from 'react'
import {
    XYPlot,
    XAxis,
    YAxis,
    Crosshair,
    LineSeriesPoint,
    DiscreteColorLegend,
    AreaSeries,
    AreaSeriesPoint,
    LineSeries,
    GradientDefs,
} from 'react-vis'
import '../../../../../node_modules/react-vis/dist/style.css'
import dateFormat from 'dateformat'
import { isMobile, withOrientationChange } from 'react-device-detect'
import { LocaleContext, nameof, pathof, useWindowDimensions } from '@unicaiot/unica-iot-gallery-core'
import { PointKpi, Type } from '../../services/types'
import colors from '../Common/Legent'
import { translations } from '../../../../translations'
import displayRanges from '../Common/DisplayRanges'
import kpiRanges from '../Common/KpiRanges'
import units from '../Common/Units'
import styles from './CompareGraph.module.scss'
import classNames from 'classnames'
import d3Locale from '../../../Core/utils/d3Locale'

interface GraphProp {
    data: { name: string; data: { x: number; y: number }[] }[]
    type: Type
    isPortrait?: boolean
    width?: number
    height?: number
}

const lines = [undefined, '2, 2', '10, 2']

const CompareGraphInternal: FunctionComponent<GraphProp> = ({ data, isPortrait, width, height, type }) => {
    const locale = useContext(LocaleContext)

    const [values, setValue] = useState<LineSeriesPoint[]>([])
    const [selected, setSelected] = useState<string>()
    const [isHighlight, setIsHighlight] = useState(false)

    const onSetValue = (value: LineSeriesPoint) => {
        const index = values?.findIndex(v => v.name === value.name)
        if (index > -1) {
            values.splice(index, 1)
        }

        values.push(value)

        setValue(Object.assign([], values))
    }

    const [areas, setAreas] = useState<{ gradient: string; data: AreaSeriesPoint[] }[]>()

    const [yDomain, setYDomain] = useState(displayRanges.get(type)?.[0])

    const legend = useMemo<
        {
            title: string
            gradient?: string
            color?: string
            strokeWidth: number
            ranges?: number[][]
        }[]
    >(() => {
        return [
            {
                title: locale._(
                    `${nameof<typeof translations>('chartWidget')}.${PointKpi[PointKpi.InSufficient].toLowerCase()}`
                ),
                gradient: PointKpi[PointKpi.InSufficient].toLowerCase(),
                color: colors.get(PointKpi.InSufficient),
                strokeWidth: 10,
                ranges: kpiRanges.get(type)?.get(PointKpi.InSufficient),
            },
            {
                title: locale._(
                    `${nameof<typeof translations>('chartWidget')}.${PointKpi[PointKpi.Moderate].toLowerCase()}`
                ),
                gradient: PointKpi[PointKpi.Moderate].toLowerCase(),
                color: colors.get(PointKpi.Moderate),
                strokeWidth: 10,
                ranges: kpiRanges.get(type)?.get(PointKpi.Moderate),
            },
            {
                title: locale._(
                    `${nameof<typeof translations>('chartWidget')}.${PointKpi[PointKpi.Sufficient].toLowerCase()}`
                ),
                gradient: PointKpi[PointKpi.Sufficient].toLowerCase(),
                color: colors.get(PointKpi.Sufficient),
                strokeWidth: 10,
                ranges: kpiRanges.get(type)?.get(PointKpi.Sufficient),
            },
        ]
    }, [locale, type])

    const sensors = useMemo<
        {
            title: string
            gradient?: string
            color?: string
            strokeWidth: number
            strokeDasharray?: string
        }[]
    >(() => {
        return [
            ...data.map((item, i) => {
                return {
                    title: item.name,
                    color: '#10264d',
                    strokeWidth: 3,
                    strokeDasharray: lines[i],
                }
            }),
        ]
    }, [data])

    useEffect(() => {
        if (data?.length > 0) {
            const x0 = Math.min(
                ...data
                    .filter(item => item.data.length > 0)
                    .map(item => {
                        return item.data[0].x
                    })
            )
            const xN = Math.max(
                ...data
                    .filter(item => item.data.length > 0)
                    .map(item => {
                        return item.data[item.data.length - 1].x
                    })
            )

            const y0 = Math.min(
                ...data
                    .filter(item => item.data.length > 0)
                    .map(item => {
                        return item.data[0].y
                    })
            )
            const yN = Math.max(
                ...data
                    .filter(item => item.data.length > 0)
                    .map(item => {
                        return item.data[item.data.length - 1].y
                    })
            )

            const yMin =
                Math.trunc(
                    Math.min(
                        displayRanges.get(type)?.[0][0] || y0,
                        Math.max(y0, displayRanges.get(type)?.[1][0] || y0)
                    ) / (displayRanges.get(type)?.[2][0] || 10)
                ) * (displayRanges.get(type)?.[2][0] || 10)

            const yMax =
                Math.ceil(
                    Math.max(
                        displayRanges.get(type)?.[0][1] || yN,
                        Math.min(yN, displayRanges.get(type)?.[1][1] || yN)
                    ) / (displayRanges.get(type)?.[2][0] || 10)
                ) * (displayRanges.get(type)?.[2][0] || 10)

            setYDomain([yMin, yMax])

            const areas: { gradient: string; data: AreaSeriesPoint[] }[] = []

            legend.forEach(area => {
                area.ranges?.forEach((range, index) => {
                    const value = {
                        y: Math.max(range[0], yMin),
                        y0: Math.min(range[1], yMax),
                    }

                    areas.push({
                        gradient: `${area.gradient}_${index}`,
                        data: [
                            { x: x0, ...value },
                            { x: xN, ...value },
                        ],
                    })
                })
            })

            setAreas(areas)
        }
    }, [data, legend, type])

    const wd = useWindowDimensions()

    return (
        <Fragment>
            <div className={styles.legends}>
                <DiscreteColorLegend
                    className={classNames(styles.legent, { [styles.isLowercase]: true })}
                    orientation="horizontal"
                    items={legend}
                />
                <DiscreteColorLegend
                    orientation="horizontal"
                    className={styles.legent}
                    items={sensors}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onItemClick={(e: any) => setSelected(e.title)}
                />
            </div>
            <XYPlot
                xType={'time'}
                width={width || (isMobile ? (isPortrait ? wd.width - 50 : 600) : 700)}
                height={height || (isMobile ? 400 : 500)}
                onMouseLeave={() => {
                    setValue([])
                    setSelected(undefined)
                }}
                onDoubleClick={() => setIsHighlight(!isHighlight)}
                style={{ margin: 'auto' }}
                yDomain={yDomain}
            >
                <GradientDefs>
                    <linearGradient
                        id={`${PointKpi[PointKpi.Sufficient].toLowerCase()}_0`}
                        x1="0%"
                        x2="0%"
                        y1="100%"
                        y2="0%"
                    >
                        <stop offset="0%" stopColor={colors.get(PointKpi.Sufficient)} stopOpacity={0.1} />
                        <stop offset="50%" stopColor={colors.get(PointKpi.Sufficient)} stopOpacity={0.2} />
                        <stop offset="100%" stopColor={colors.get(PointKpi.Moderate)} stopOpacity={0.1} />
                    </linearGradient>
                    <linearGradient
                        id={`${PointKpi[PointKpi.Sufficient].toLowerCase()}_1`}
                        x1="0%"
                        x2="0%"
                        y1="100%"
                        y2="0%"
                    >
                        <stop offset="0%" stopColor={colors.get(PointKpi.Moderate)} stopOpacity={0.1} />
                        <stop offset="50%" stopColor={colors.get(PointKpi.Sufficient)} stopOpacity={0.2} />
                        <stop offset="100%" stopColor={colors.get(PointKpi.Sufficient)} stopOpacity={0.1} />
                    </linearGradient>
                    <linearGradient
                        id={`${PointKpi[PointKpi.Moderate].toLowerCase()}_0`}
                        x1="0%"
                        x2="0%"
                        y1="100%"
                        y2="0%"
                    >
                        <stop offset="0%" stopColor={colors.get(PointKpi.Moderate)} stopOpacity={0.1} />
                        <stop offset="50%" stopColor={colors.get(PointKpi.Moderate)} stopOpacity={0.2} />
                        <stop offset="100%" stopColor={colors.get(PointKpi.InSufficient)} stopOpacity={0.1} />
                    </linearGradient>
                    <linearGradient
                        id={`${PointKpi[PointKpi.Moderate].toLowerCase()}_1`}
                        x1="0%"
                        x2="0%"
                        y1="100%"
                        y2="0%"
                    >
                        <stop offset="0%" stopColor={colors.get(PointKpi.InSufficient)} stopOpacity={0.1} />
                        <stop offset="50%" stopColor={colors.get(PointKpi.Moderate)} stopOpacity={0.2} />
                        <stop offset="100%" stopColor={colors.get(PointKpi.Moderate)} stopOpacity={0.1} />
                    </linearGradient>
                    <linearGradient
                        id={`${PointKpi[PointKpi.InSufficient].toLowerCase()}_0`}
                        x1="0%"
                        x2="0%"
                        y1="100%"
                        y2="0%"
                    >
                        <stop offset="0%" stopColor={colors.get(PointKpi.InSufficient)} stopOpacity={0.1} />
                        <stop offset="100%" stopColor={colors.get(PointKpi.InSufficient)} stopOpacity={0.2} />
                    </linearGradient>
                    <linearGradient
                        id={`${PointKpi[PointKpi.InSufficient].toLowerCase()}_1`}
                        x1="0%"
                        x2="0%"
                        y1="100%"
                        y2="0%"
                    >
                        <stop offset="0%" stopColor={colors.get(PointKpi.InSufficient)} stopOpacity={0.2} />
                        <stop offset="100%" stopColor={colors.get(PointKpi.InSufficient)} stopOpacity={0.1} />
                    </linearGradient>
                    <pattern id="circle" x="4" y="11" width="15" height="15" patternUnits="userSpaceOnUse">
                        <circle cx="5" cy="5" r="5" fill="#10264d" />
                    </pattern>
                </GradientDefs>

                <XAxis
                    tickTotal={isMobile && isPortrait ? 5 : 10}
                    style={{
                        fontSize: 10,
                        line: { stroke: '#10264d' },
                        ticks: { fill: '#10264d', fontWeight: 500 },
                    }}
                    hideLine={true}
                    tickFormat={d3Locale}
                />
                <YAxis
                    tickTotal={isMobile && isPortrait ? 5 : 10}
                    style={{
                        fontSize: 10,
                        line: { stroke: '#10264d' },
                        ticks: { fill: '#10264d', fontWeight: 500 },
                    }}
                    tickFormat={t => new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 }).format(t)}
                    hideLine={true}
                />

                {areas?.map((area, index) => {
                    return <AreaSeries color={`url(#${area.gradient})`} data={area.data} key={`as_${index}`} />
                })}

                {data.map((item, i) => {
                    return (
                        <LineSeries
                            key={`ls_${i}`}
                            data={item.data}
                            onNearestX={d => onSetValue({ ...d, name: item.name })}
                            onSeriesClick={() => {
                                setSelected(item.name)
                            }}
                            curve={'curveMonotoneX'}
                            color={'#10264d'}
                            style={{
                                strokeWidth: item.name === selected ? 6 : 3,
                                strokeDasharray: lines[i],
                            }}
                        />
                    )
                })}

                {values && values.length > 0 && (
                    <Crosshair
                        className={styles.crosshair}
                        values={values}
                        titleFormat={() => {
                            return {
                                title: locale._(translations.dataOverview.timeTitle),
                                value: locale._(translations.dataOverview.timeValue, {
                                    date: dateFormat(Math.max(...values.map(v => v.x)), 'd mmmm yyyy').toLowerCase(),
                                    time: dateFormat(Math.max(...values.map(v => v.x)), 'HH:MM'),
                                }),
                            }
                        }}
                        itemsFormat={() => {
                            return values.map((v: LineSeriesPoint) => {
                                return {
                                    title: (
                                        <span style={{ fontWeight: v.name === selected ? 'bold' : 'normal' }}>
                                            {locale._(
                                                `${pathof<typeof translations>()._('dataOverview').path}.${type}`
                                            )}{' '}
                                            ({v.name})
                                        </span>
                                    ),
                                    value: (
                                        <span style={{ fontWeight: v.name === selected ? 'bold' : 'normal' }}>{`${
                                            v.y
                                        } ${units.get(type)}`}</span>
                                    ),
                                }
                            })
                        }}
                        style={{
                            line: { backgroundColor: '#fff' },
                            box: { backgroundColor: '#fff', borderColor: '#10264d' },
                        }}
                    />
                )}
            </XYPlot>
        </Fragment>
    )
}

const CompareGraph = withOrientationChange(CompareGraphInternal)

export { CompareGraph }
