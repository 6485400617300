import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router-dom'

import { Auth, InfoText, LayoutType, PageLayout, TreeNode, TreeView } from '@unicaiot/unica-iot-gallery-airinsight'

import styles from './PowerBIEmbededWorkspacesView.module.scss'
import { useGetEmbedWorkspaces } from '../services/hooks'
import { LocaleContext, PageError } from '@unicaiot/unica-iot-gallery-core'
import { translations } from '../../../translations'
import { routes } from '../../../routes'
import { EmbedReport } from '../services/types'

export const PowerBIEmbededWorkspacesView: FunctionComponent<RouteComponentProps> = () => {
    return (
        <Auth>
            <PowerBIEmbededReportLayout />
        </Auth>
    )
}

const PowerBIEmbededReportLayout: FunctionComponent = () => {
    const [data, setData] = useState<TreeNode[]>([])

    const locale = useContext(LocaleContext)
    const history = useHistory()

    const result = useGetEmbedWorkspaces()

    if (result.error) {
        throw new PageError(result.error)
    }

    useEffect(() => {
        setData(
            (result.data || [])
                .reduce<{ id: string; report: EmbedReport }[]>(
                    (acc, w) =>
                        acc.concat(
                            w.reports.map(r => {
                                return { id: w.id, report: r }
                            })
                        ),
                    []
                )
                .map((r, i) => {
                    return {
                        node: <InfoText className={styles.base}>{r.report.name}</InfoText>,
                        loading: false,
                        active: data[i]?.active || false,
                        onClick: () => {
                            history.push(routes.powerBiReport(r.id, r.report.id))
                        },
                        children: null,
                    }
                })
        )

        // eslint-disable-next-line
    }, [result.data])

    return (
        <PageLayout
            pageType={LayoutType.light}
            title={locale._(translations.pbi.title)}
            loading={result.loading}
            backRoute={routes.dashboard}
            content={() => <TreeView root={<Fragment />} nodes={data} />}
            showFooter={true}
            showHeader={true}
        />
    )
}
