import React, { FunctionComponent } from 'react'
import { Route, Switch, withRouter, RouteComponentProps, Redirect } from 'react-router-dom'
import { config } from './config'
import { routes } from './routes'
import {
    TelemetryProvider,
    RedirectorTemplate,
    Shield,
    withLogin,
    useResize,
    useScroll,
} from '@unicaiot/unica-iot-gallery-core'
import { GlobalErrorView, LoginView, ResetPasswordView, AdRedirectView } from '@unicaiot/unica-iot-gallery-airinsight'
import { DataExportView } from './domain/DataExplorer/Views/DataExportView/DataExportView'
import { DashbaordView } from './domain/Dashboard/Views/DashboardView'
import { ExplorerView } from './domain/DataExplorer/Views/ExplorerView/ExplorerView'
import { ComparerView } from './domain/DataExplorer/Views/ComparerView/ComparerView'
import dateFormat from 'dateformat'
import { registerLocale, setDefaultLocale } from 'react-datepicker'
import nl from 'date-fns/locale/nl'
import { PowerBIEmbededReportView } from './domain/PowerBI/PowerBIEmbededReportView/PowerBIEmbededView'
import { PowerBIEmbededWorkspacesView } from './domain/PowerBI/PowerBIEmbededWorkspacesView/PowerBIEmbededWorkspacesView'

setDefaultLocale(config.defaultLocale)

dateFormat.i18n.monthNames = [
    'jan',
    'febr',
    'mrt',
    'apr',
    'mei',
    'juni',
    'juli',
    'aug',
    'sep',
    'okt',
    'nov',
    'dec',
    'januari',
    'februari',
    'maart',
    'april',
    'mei',
    'juni',
    'juli',
    'augustus',
    'september',
    'oktober',
    'november',
    'december',
]

registerLocale(config.defaultLocale, nl)

withLogin()

export const Redirector: FunctionComponent<RouteComponentProps> = props => {
    return (
        <RedirectorTemplate
            {...props}
            getRedirector={() => {
                const path = config.defaultPath

                return <Redirect to={path} />
            }}
        />
    )
}

const TelemetryProviderWithRouter = withRouter(TelemetryProvider)

export const RootView: FunctionComponent = () => {
    useScroll()

    useResize()

    return (
        <Shield
            getErrorView={() => {
                return <GlobalErrorView />
            }}
        >
            <TelemetryProviderWithRouter instrumentationKey={config.instrumentationKey}>
                <Switch>
                    <Route exact={true} path={routes.login} component={LoginView} />
                    <Route exact={true} path={routes.resetPassword} component={ResetPasswordView} />
                    <Route exact={true} path={config.ad.redirectPath} component={AdRedirectView} />
                    <Route exact={true} path={routes.index} component={Redirector} />
                    <Route exact={true} path={routes.dashboard} component={DashbaordView} />
                    <Route exact={true} path={routes.powerBiReport()} component={PowerBIEmbededReportView} />
                    <Route exact={true} path={routes.powerBiWorkspaces} component={PowerBIEmbededWorkspacesView} />
                    <Route exact={true} path={routes.export()} component={DataExportView} />
                    <Route exact={true} path={routes.explorer()} component={ExplorerView} />
                    <Route exact={true} path={routes.comparer()} component={ComparerView} />
                    <Redirect to={config.defaultPath} />
                </Switch>
            </TelemetryProviderWithRouter>
        </Shield>
    )
}
