import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react'
import styles from './BuildingsOverview.module.scss'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
    LocaleContext,
    PageError,
} from '@unicaiot/unica-iot-gallery-core'
import {
    TreeView,
    QuestionMark,
    InfoText,
    TextWeightEnumType,
    TextSizeEnumType,
    TextColorEnumType,
    TreeNode,
    Icons,
    TextButton,
    BadgeIcon,
    Icon,
    Input,
    IconType,
} from '@unicaiot/unica-iot-gallery-airinsight'
import dateFormat from 'dateformat'
import { isMobile } from 'react-device-detect'
import { Building, IEntity, ISensor, PointKpi, Room, Type } from '../../services/types'
import { useGetOverview } from '../../services/hooks'
import legend from '../Common/Legent'
import units from '../Common/Units'
import trunc from '../Common/Trunc'
import { Trans } from '@lingui/react'
import { translations } from '../../../../translations'
import { explorerService } from '../../services/service'

export interface Props {
    type: Type
    isExpanded?: boolean
    onClick?: (sensor: ISensor) => void
    onRestore?: (sensor: ISensor[]) => void
    compareEnabled?: boolean
    editEnable?: boolean
    onCompareAdd?: (sensor: ISensor) => void
    sensors?: ISensor[]
    restoration?: string[]
    kpiType?: PointKpi
    exclude?: boolean
}

const iconsMap = new Map<string, IconType>()
iconsMap.set('room', Icons.room)
iconsMap.set('floor', Icons.floor)
iconsMap.set('space', Icons.space)

export const BuildingsOverview: FunctionComponent<Props> = ({
    isExpanded = false,
    compareEnabled = false,
    editEnable = false,
    onClick,
    onCompareAdd,
    onRestore,
    sensors,
    restoration,
    type,
    kpiType,
    exclude,
}) => {
    const locale = useContext(LocaleContext)
    const [editEntity, setEditEntity] = useState<IEntity>()
    const [editName, setEditName] = useState<string>()
    const [deleteId, setDeleteId] = useState<string>()
    const [acting, setActing] = useState(false)
    const [focusing, setFocusing] = useState(sensors || restoration || false)

    const [data, setData] = useState<TreeNode[]>([])

    const result = useGetOverview(type, kpiType, exclude)

    if (result.error) {
        throw new PageError(result.error)
    }

    const containsB = (building: Building) => {
        return building.rooms?.some(containsR)
    }

    const containsR = (room: Room) => {
        return room.sensors?.some(rs => sensors?.some(s => s.id === rs.id)) || room.rooms?.some(containsR)
    }

    const color = (kpi?: PointKpi) => {
        return kpi ? legend.get(kpi) : TextColorEnumType.white
    }

    const saveAsync = async (type: 'rooms' | 'buildings') => {
        if (!editEntity) {
            return
        }

        setActing(true)

        try {
            await explorerService.putEntity(type, { ...editEntity, name: editName || editEntity.name })

            editEntity.name = editName || editEntity.name

            setEditEntity(undefined)
            setEditName(undefined)
        } catch {}

        setActing(false)
    }

    const deleteBuildingAsync = async (id: string, index: number) => {
        setActing(true)

        setDeleteId(id)

        try {
            await explorerService.deleteEntity('buildings', id)

            result.data?.splice(index, 1)

            setDeleteId(undefined)
        } catch {}

        setActing(false)
    }

    const findRooms = (index: [number, number[]]) => {
        const b = result.data?.[index[0]]

        let rs = b?.rooms

        index[1].forEach(j => {
            if (rs) {
                rs = rs[j].rooms
            }
        })

        return rs
    }

    const findNodes = (index: [number, number[]]) => {
        const b = data?.[index[0]]

        let rs = b?.children

        index[1].forEach(j => {
            if (rs) {
                rs = rs[j].children
            }
        })

        return rs
    }

    const deleteRoomAsync = async (id: string, fullIndex: [number, number[]], index: number) => {
        setActing(true)

        setDeleteId(id)

        try {
            await explorerService.deleteEntity('rooms', id)

            findRooms(fullIndex)?.splice(index, 1)

            setDeleteId(undefined)
        } catch {}

        setActing(false)
    }

    useEffect(() => {
        const localSensors = sensors || []

        const deepCheck = (rooms?: Room[]) => {
            rooms?.forEach(r => {
                r.sensors?.forEach(s => {
                    if (restoration?.some(r => r === s.identifier)) {
                        localSensors.push(s)
                    }
                })
                deepCheck(r.rooms)
            })
        }

        result.data?.forEach(b => {
            deepCheck(b.rooms)
        })

        const expand = (index: [number, number[]], rooms?: Room[]): TreeNode[] => {
            return (
                rooms?.map((room, j) => {
                    return {
                        node:
                            editEntity?.id === room.id ? (
                                <Fragment>
                                    <span className={styles.inputWrapper} onClick={e => e.stopPropagation()}>
                                        <Input
                                            focus={true}
                                            className={styles.input}
                                            value={editName}
                                            onChange={v => setEditName(v)}
                                        />
                                        {acting ? (
                                            <ActivityIndicator size={ActivityIndicatorEnumType.small} />
                                        ) : (
                                            <Fragment>
                                                <TextButton onClick={() => saveAsync('rooms')}>
                                                    <Icon icon={Icons.confirm} size={[18, 18]} />
                                                </TextButton>
                                                <TextButton
                                                    onClick={() => {
                                                        setEditEntity(undefined)
                                                    }}
                                                >
                                                    <Icon icon={Icons.cancel} size={[18, 18]} />
                                                </TextButton>
                                            </Fragment>
                                        )}
                                    </span>
                                </Fragment>
                            ) : (
                                <Fragment>
                                    {editEnable && (
                                        <span className={styles.compareAddWrapper} onClick={e => e.stopPropagation()}>
                                            {(room.sensors?.length && room.sensors?.length > 0) ||
                                            (room.rooms?.length && room.rooms?.length > 0) ? (
                                                <TextButton
                                                    className={styles.compareAdd}
                                                    onClick={() => {
                                                        setEditEntity(room)
                                                        setEditName(room.name)
                                                    }}
                                                >
                                                    <Icon icon={Icons.edit} size={[18, 18]} />
                                                </TextButton>
                                            ) : acting && deleteId === room.id ? (
                                                <ActivityIndicator size={ActivityIndicatorEnumType.small} />
                                            ) : (
                                                <TextButton
                                                    className={styles.compareAdd}
                                                    onClick={() => {
                                                        deleteRoomAsync(room.id, index, j)
                                                    }}
                                                >
                                                    <Icon icon={Icons.delete} size={[18, 18]} />
                                                </TextButton>
                                            )}
                                        </span>
                                    )}
                                    <span className={styles.levelName}>
                                        <Icon size={[18, 14]} icon={iconsMap.get(room.type) || Icons.floor} />
                                        <InfoText>{room.name}</InfoText>
                                    </span>
                                    <InfoText
                                        color={color(room.averageKpi?.kpi)}
                                        weight={TextWeightEnumType.bold}
                                        className={styles.avg}
                                    >
                                        {!exclude && (
                                            <Fragment>
                                                &#8777; {room.averageKpi?.value?.toFixed(trunc.get(type)) || 'n/a'}{' '}
                                                {units.get(type)}
                                            </Fragment>
                                        )}
                                    </InfoText>
                                </Fragment>
                            ),
                        loading: false,
                        active: findNodes(index)?.[j]?.active || containsR(room) || isExpanded,
                        children: expand([index[0], [...index[1], j]], room.rooms).concat(
                            room?.sensors?.map((sensor, y) => {
                                const infoPoint = !exclude ? (
                                    <div className={styles.info}>
                                        <InfoText color={TextColorEnumType.grey} size={TextSizeEnumType.medium}>
                                            {sensor.latestKpi?.time
                                                ? locale._(translations.dataOverview.timeValue, {
                                                      date: dateFormat(
                                                          `${sensor.latestKpi?.time}`,
                                                          'd mmmm yyyy'
                                                      ).toLowerCase(),
                                                      time: dateFormat(`${sensor.latestKpi?.time}`, 'HH:MM'),
                                                  })
                                                : 'n/a'}
                                            &nbsp;
                                        </InfoText>
                                        <InfoText color={color(sensor.latestKpi?.kpi)} weight={TextWeightEnumType.bold}>
                                            {sensor.latestKpi?.value || 'n/a'} {units.get(type)}
                                        </InfoText>
                                    </div>
                                ) : (
                                    <Fragment />
                                )
                                return {
                                    node: (
                                        <Fragment>
                                            {compareEnabled && (
                                                <span
                                                    className={styles.compareAddWrapper}
                                                    onClick={e => e.stopPropagation()}
                                                >
                                                    <TextButton
                                                        className={styles.compareAdd}
                                                        onClick={() => {
                                                            onCompareAdd &&
                                                                onCompareAdd({
                                                                    id: sensor.id,
                                                                    name: sensor.name,
                                                                    identifier: sensor.identifier,
                                                                })
                                                        }}
                                                    >
                                                        <BadgeIcon
                                                            icon={Icons['compare-add']}
                                                            size={[18, 18]}
                                                            shift={2}
                                                            badge={
                                                                localSensors?.some(s => s.id === sensor.id) ? '-' : '+'
                                                            }
                                                        />
                                                    </TextButton>
                                                </span>
                                            )}
                                            <InfoText size={TextSizeEnumType.medium} className={styles.name}>
                                                <span
                                                    ref={span => {
                                                        if (
                                                            localSensors?.some(s => s.id === sensor.id) &&
                                                            span &&
                                                            focusing
                                                        ) {
                                                            span.scrollIntoView()
                                                            setFocusing(false)
                                                        }
                                                    }}
                                                >
                                                    {sensor.name?.split('-')?.[0]}
                                                </span>
                                            </InfoText>
                                            {!exclude &&
                                                (isMobile ? (
                                                    <div className={styles.qm} onClick={e => e.stopPropagation()}>
                                                        <QuestionMark
                                                            id={`${index[0]}-${index[1].join('-')}-${j}-${y}`}
                                                        >
                                                            {infoPoint}
                                                        </QuestionMark>
                                                    </div>
                                                ) : (
                                                    <div className={styles.time}>{infoPoint}</div>
                                                ))}
                                        </Fragment>
                                    ),
                                    onClick: () => {
                                        onClick &&
                                            onClick({
                                                id: sensor.id,
                                                name: sensor.name,
                                                identifier: sensor.identifier,
                                            })
                                        !onClick &&
                                            onCompareAdd &&
                                            onCompareAdd({
                                                id: sensor.id,
                                                name: sensor.name,
                                                identifier: sensor.identifier,
                                            })
                                    },
                                    loading: false,
                                    active: false,
                                    children: null,
                                    clicked: localSensors?.some(s => s.id === sensor.id),
                                }
                            }) || []
                        ),
                    }
                }) || []
            )
        }

        setData(
            (result.data || []).map((building, i) => {
                return {
                    node:
                        editEntity?.id === building.id ? (
                            <Fragment>
                                <span className={styles.inputWrapper} onClick={e => e.stopPropagation()}>
                                    <Input
                                        focus={true}
                                        className={styles.input}
                                        value={editName}
                                        onChange={v => setEditName(v)}
                                    />
                                    {acting ? (
                                        <ActivityIndicator size={ActivityIndicatorEnumType.small} />
                                    ) : (
                                        <Fragment>
                                            <TextButton onClick={() => saveAsync('buildings')}>
                                                <Icon icon={Icons.confirm} size={[18, 18]} />
                                            </TextButton>
                                            <TextButton
                                                onClick={() => {
                                                    setEditEntity(undefined)
                                                }}
                                            >
                                                <Icon icon={Icons.cancel} size={[18, 18]} />
                                            </TextButton>
                                        </Fragment>
                                    )}
                                </span>
                            </Fragment>
                        ) : (
                            <Fragment>
                                {editEnable && (
                                    <span className={styles.compareAddWrapper} onClick={e => e.stopPropagation()}>
                                        {building.rooms?.length && building.rooms?.length > 0 ? (
                                            <TextButton
                                                className={styles.compareAdd}
                                                onClick={() => {
                                                    setEditEntity(building)
                                                    setEditName(building.name)
                                                }}
                                            >
                                                <Icon icon={Icons.edit} size={[18, 18]} />
                                            </TextButton>
                                        ) : acting && deleteId === building.id ? (
                                            <ActivityIndicator size={ActivityIndicatorEnumType.small} />
                                        ) : (
                                            <TextButton
                                                className={styles.compareAdd}
                                                onClick={() => {
                                                    deleteBuildingAsync(building.id, i)
                                                }}
                                            >
                                                <Icon icon={Icons.delete} size={[18, 18]} />
                                            </TextButton>
                                        )}
                                    </span>
                                )}
                                <Icon icon={Icons.building} size={[18, 18]} />
                                <InfoText>{building.name}</InfoText>

                                <InfoText
                                    color={color(building.averageKpi?.kpi)}
                                    weight={TextWeightEnumType.bold}
                                    className={styles.avg}
                                >
                                    {!exclude && (
                                        <Fragment>
                                            &#8777; {building.averageKpi?.value?.toFixed(trunc.get(type)) || 'n/a'}{' '}
                                            {units.get(type)}
                                        </Fragment>
                                    )}
                                </InfoText>
                            </Fragment>
                        ),
                    loading: false,
                    active: data[i]?.active || containsB(building) || isExpanded,
                    children: expand([i, []], building.rooms),
                }
            })
        )

        result.data && restoration && localSensors && onRestore && onRestore(localSensors)

        // eslint-disable-next-line
    }, [result.data, type, compareEnabled, sensors, restoration, exclude, editEnable, editEntity, editName, acting])

    return (
        <Fragment>
            {result.loading ? (
                <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
            ) : (
                <TreeView
                    root={
                        <InfoText
                            className={styles.root}
                            size={TextSizeEnumType.large}
                            weight={TextWeightEnumType.bold}
                        >
                            <Trans id="buildingsOverview.title" message="Gebouwen" />
                        </InfoText>
                    }
                    nodes={data}
                />
            )}
        </Fragment>
    )
}
