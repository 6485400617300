import { defineMessage } from '@lingui/macro'

export const translations = {
    login: {
        reset: defineMessage({ id: 'login.reset', message: 'Resetten' }),
        ad: defineMessage({ id: 'login.ad', message: 'Aanmelden met Microsoft' }),
        signIn: defineMessage({ id: 'login.signIn', message: 'Inloggen' }),
        change: defineMessage({ id: 'login.change', message: 'Wijzig' }),
        emailPlaceholder: defineMessage({ id: 'login.emailPlaceholder', message: 'E-mail' }),
        passwordPlaceholder: defineMessage({ id: 'login.passwordPlaceholder', message: 'Wachtwoord' }),
        confirmPasswordPlaceholder: defineMessage({
            id: 'login.confirmPasswordPlaceholder',
            message: 'Bevestig wachtwoord',
        }),
        forget: defineMessage({ id: 'login.forget', message: 'Wachtwoord vergeten?' }),
        back: defineMessage({ id: 'login.back', message: 'Terug' }),
        emailSendTitle: defineMessage({
            id: 'login.emailSendTitle',
            message: 'We hebben zojuist een e-mail verstuurd met een link om uw wachtwoord te wijzigen.',
        }),
        emailSendSubtitle: defineMessage({
            id: 'login.emailSendSubtitle',
            message:
                'Het kan een paar minuten duren voordat u deze mail ontvangt. Controleer uw spamfolder als er geen e-mail van ons in uw inbox verschijnt.',
        }),
    },
    errorView: {
        backToDefault: defineMessage({ id: 'errorView.backToDefault', message: 'Ga terug naar de standaard pagina' }),
    },
    errorLayout: {
        title: defineMessage({ id: 'ErrorLayout.title', message: 'Oeps, er is iets fout gegaan.' }),
    },
    dataExportView: {
        title: defineMessage({ id: 'dataExportView.title', message: 'De export' }),
    },
    welcomeWidget: {
        title: defineMessage({ id: 'welcomeWidget.title', message: 'Welkom {name}!' }),
        subTitle: defineMessage({
            id: 'welcomeWidget.subTitle',
            message:
                'In onderstaand overzicht zie je de luchtkwaliteit, temperatuur en luchtvochtigheid van jouw gebouwen en ruimtes. Selecteer een specifieke ruimte voor meer details.',
        }),
    },
    announcmentWidget: {
        title: defineMessage({ id: 'announcmentWidget.title', message: 'Nieuws' }),
        info: defineMessage({
            id: 'announcmentWidget.info',
            message:
                'Er is een nieuwe versie van het portal gelanceerd met een verbeterde gebruikerservaring en een vernieuwd design. Voor vragen of suggesties kun je contact opnemen via <a href="mailto:buildinginsight@unica.nl">buildinginsight@unica.nl</a> .',
        }),
    },
    chartWidget: {
        sufficient: defineMessage({ id: 'chartWidget.sufficient', message: 'Goed' }),
        moderate: defineMessage({ id: 'chartWidget.moderate', message: 'Gemiddeld' }),
        insufficient: defineMessage({ id: 'chartWidget.insufficient', message: 'Slecht' }),
        unknown: defineMessage({ id: 'chartWidget.unknown', message: 'Onbekend' }),
        avg: defineMessage({ id: 'chartWidget.avg', message: '≉ {avg} {unit}' }),
        title: defineMessage({ id: 'chartWidget.title', message: 'Totaal' }),
        total: defineMessage({ id: 'chartWidget.total', message: '{total} sensoren' }),
        subTitle: defineMessage({
            id: 'chartWidget.subTitle',
            message: 'Selecteer een sensor voor meer informatie.',
        }),
        prediction: defineMessage({ id: 'chartWidget.prediction', message: 'De voorspelling' }),
    },
    explorerView: {
        subTitle: defineMessage({
            id: 'explorerView.subTitle',
            message: 'Ontdek hier de {type} in de grafiek.',
        }),
    },
    dataOverview: {
        co2: defineMessage({ id: 'dataOverview.co2', message: 'Luchtkwaliteit' }),
        temperature: defineMessage({ id: 'dataOverview.temperature', message: 'Temperatuur' }),
        humidity: defineMessage({ id: 'dataOverview.humidity', message: 'Luchtvochtigheid' }),
        timeTitle: defineMessage({ id: 'dataOverview.timeTitle', message: 'Tijd' }),
        timeValue: defineMessage({ id: 'dataOverview.timeValue', message: 'op {date} om {time}' }),
    },
    dataOverviewLayout: {
        back: defineMessage({ id: 'dataOverviewLayout.back', message: 'Terug naar alle gebouwen' }),
    },
    filters: {
        selectStatusAll: defineMessage({
            id: 'filters.selectStatusAll',
            message: 'Alle',
        }),
        selectStatusLabel: defineMessage({
            id: 'filters.selectStatusLabel',
            message: 'Status ruimte',
        }),
        periodLabel: defineMessage({
            id: 'filters.periodLabel',
            message: 'Periode',
        }),
        tillLabel: defineMessage({
            id: 'filters.tillLabel',
            message: 'tot',
        }),
        latestLabel: defineMessage({
            id: 'filters.todayLabel',
            message: 'Laatste',
        }),
    },
    iconsWidget: {
        title: defineMessage({ id: 'iconsWidget.title', message: 'Iconen' }),
        subTitle: defineMessage({
            id: 'iconsWidget.subTitle',
            message: 'Hier vind je de betekenis van de verschillende iconen.',
        }),
        info: defineMessage({ id: 'iconsWidget.info', message: 'Hulp' }),
        profile: defineMessage({ id: 'iconsWidget.profile', message: 'Profiel' }),
        logout: defineMessage({ id: 'iconsWidget.logout', message: 'Uitloggen' }),
        full: defineMessage({ id: 'iconsWidget.full', message: 'Vergroten' }),
        export: defineMessage({ id: 'iconsWidget.export', message: 'Exportpagina' }),
        pulse: defineMessage({ id: 'iconsWidget.pulse', message: 'Klikbare actie' }),
        close: defineMessage({ id: 'iconsWidget.close', message: 'Sluiten' }),
        compare: defineMessage({ id: 'iconsWidget.compare', message: 'Vergelijken' }),
        reset: defineMessage({ id: 'iconsWidget.reset', message: 'Resetten' }),
        excel: defineMessage({ id: 'iconsWidget.excel', message: 'Exporteren' }),
        compareAdd: defineMessage({ id: 'iconsWidget.compareAdd', message: 'Voeg toe aan vergelijking' }),
        compareRemove: defineMessage({ id: 'iconsWidget.compareRemove', message: 'Verwijder uit vergelijking' }),
    },
    tipsWidget: {
        title: defineMessage({ id: 'tipsWidget.title', message: 'Tips' }),
        subTitle: defineMessage({
            id: 'tipsWidget.subTitle',
            message: 'Het zijn handige tips over verschillende features.',
        }),
        tip1: defineMessage({
            id: 'tipsWidget.tip1',
            message: 'Klik op een balk in de BarChart-widget om gebouwen uit te filteren.',
        }),
    },
    resetPasswordWidget: {
        title: defineMessage({ id: 'resetPasswordWidget.title', message: 'Wachtwoord' }),
        subTitle: defineMessage({
            id: 'resetPasswordWidget.subTitle',
            message: 'U kunt hier uw wachtwoord opnieuw instellen. De link wordt u per e-mail toegestuurd.',
        }),
        reset: defineMessage({ id: 'resetPasswordWidget.reset', message: 'Resetten' }),
        successMessage: defineMessage({
            id: 'resetPasswordWidget.successMessage',
            message: 'Uw aanvraag is succesvol ingediend. Bekijk je emails.',
        }),
    },
    profile: {
        profile: defineMessage({ id: 'profile.profile', message: 'Profiel' }),
        help: defineMessage({ id: 'profile.help', message: 'Hulp' }),
        logout: defineMessage({ id: 'profile.logout', message: 'Uitloggen' }),
    },
    pbi: {
        title: defineMessage({ id: 'pbi.title', message: 'De rapporten' }),
    },
}
