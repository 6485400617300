import { ErrorLayout, HeadingLevel, Title, Widget } from '@unicaiot/unica-iot-gallery-airinsight'
import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react'
import { useGetBuildings, useGetDatas } from '../../services/hooks'
import '../../../../../node_modules/react-vis/dist/style.css'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
    PageError,
    useWindowDimensions,
} from '@unicaiot/unica-iot-gallery-core'
import styles from './CompareGraphWidget.module.scss'
import { isMobileOnly } from 'react-device-detect'
import { Type } from '../../services/types'
import { CompareGraph } from '../Comparer/CompareGraph'
import { extractInfo } from '../Helpers/SensorHelper'

export interface Props {
    sensors: { id: string; name: string }[]
    type: Type
    startDate: Date
    endDate: Date
    latest?: boolean
}

export const CompareGraphWidget: FunctionComponent<Props> = ({ sensors, type, startDate, endDate, latest = true }) => {
    const [data, setData] = useState<{ name: string; data: { x: number; y: number }[] }[]>()

    const ids = useMemo<string[]>(() => {
        return sensors.map(sensor => sensor.id)
    }, [sensors])

    const bResult = useGetBuildings(ids)

    if (bResult?.error) {
        throw new RangeError(bResult.error)
    }

    const info = useMemo(() => {
        return (
            bResult.data?.map(extractInfo).reduce((a, c) => {
                return {
                    path: `${a.path} vs ${c.path}`,
                    name: `${a.name} vs ${c.name}`,
                }
            }) || {
                path: '',
                name: '',
            }
        )
    }, [bResult.data])

    const result = useGetDatas(ids, startDate, endDate)

    if (result?.error) {
        throw new PageError(result.error)
    }

    useEffect(() => {
        if (result.data) {
            const data = result.data.map(item => {
                return {
                    name: sensors.find(s => s.id === item.meta.id.substring(2))?.name || '',
                    data: item.rows
                        .map(r => {
                            return {
                                x: Date.parse(r.ts.substring(2).split(' ')[0]),
                                y: Number.parseFloat(r.val.substring(2)),
                            }
                        })
                        .sort((a, b) => {
                            let comparison = 0
                            if (a.x > b.x) {
                                comparison = 1
                            } else if (a.x < b.x) {
                                comparison = -1
                            }
                            return comparison
                        }),
                }
            })

            if (latest) {
                const minStart =
                    Math.min(...data.filter(d => d.data.length > 0).map(d => d.data[d.data.length - 1].x)) -
                    60 * 60 * 1000

                data.forEach(d => {
                    if (d.data.length > 0) {
                        d.data = d.data.filter(e => e.x >= minStart)
                    }
                })
            }

            const maxTime = Math.max(...data.filter(d => d.data.length > 0).map(d => d.data[d.data.length - 1].x))
            const minTime = Math.max(...data.filter(d => d.data.length > 0).map(d => d.data[0].x))

            data.forEach(d => {
                if (d.data.length > 0) {
                    d.data[0].x = minTime
                    d.data[d.data.length - 1].x = maxTime
                }
            })

            setData(data)
        }
    }, [result.data, sensors, latest])

    const wd = useWindowDimensions()

    const width = isMobileOnly ? wd.width - 2 * 1 * 8 : (wd.width - 8 * 13) * 0.5 - 2 * 5 * 8
    const height = isMobileOnly ? 200 : wd.height - 65 - 150 - 8 * 23 - 2 * 8 * 8

    return (
        <Widget className={styles.widget} borders={true}>
            {bResult.loading || result.loading || !data ? (
                <ActivityIndicator className={styles.loader} size={ActivityIndicatorEnumType.large} />
            ) : result.error ? (
                <ErrorLayout />
            ) : (
                <Fragment>
                    <Title className={styles.titlePlace} headingLevel={HeadingLevel.h1}>
                        {info.path}
                    </Title>
                    <Title className={styles.titleSensor} headingLevel={HeadingLevel.h1}>
                        {info.name}
                    </Title>
                    <div className={styles.chart}>
                        <CompareGraph data={data} width={width} height={height} type={type} />
                    </div>
                </Fragment>
            )}
        </Widget>
    )
}
