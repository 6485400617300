import React, { FunctionComponent, useState, useEffect, useContext, useMemo, Fragment } from 'react'
import {
    XYPlot,
    XAxis,
    YAxis,
    Crosshair,
    LineSeriesPoint,
    DiscreteColorLegend,
    AreaSeries,
    AreaSeriesPoint,
    LineSeries,
    GradientDefs,
    CustomSVGSeries,
} from 'react-vis'
import '../../../../../node_modules/react-vis/dist/style.css'
import dateFormat from 'dateformat'
import { isMobile, withOrientationChange } from 'react-device-detect'
import { LocaleContext, nameof, pathof, useWindowDimensions } from '@unicaiot/unica-iot-gallery-core'
import { PointKpi, Type } from '../../services/types'
import colors from '../Common/Legent'
import { translations } from '../../../../translations'
import displayRanges from '../Common/DisplayRanges'
import kpiRanges from '../Common/KpiRanges'
import units from '../Common/Units'
import trunc from '../Common/Trunc'
import styles from './Graph.module.scss'
import d3Locale from '../../../Core/utils/d3Locale'
import classNames from 'classnames'

interface GraphProp {
    data: { x: number; y: number }[]
    predictions?: { x: number; y: number }[]
    type: Type
    isPortrait?: boolean
    width?: number
    height?: number
}

const GraphInternal: FunctionComponent<GraphProp> = ({ data, predictions, isPortrait, width, height, type }) => {
    const locale = useContext(LocaleContext)

    const [value, setValue] = useState<LineSeriesPoint>()

    const [areas, setAreas] = useState<{ gradient: string; data: AreaSeriesPoint[] }[]>()

    const [avg, setAvg] = useState<{ x: number; y: number }>()

    const [yDomain, setYDomain] = useState(displayRanges.get(type)?.[0])

    const legend = useMemo(() => {
        return [
            {
                title: locale._(
                    `${nameof<typeof translations>('chartWidget')}.${PointKpi[PointKpi.Sufficient].toLowerCase()}`
                ),
                gradient: PointKpi[PointKpi.Sufficient].toLowerCase(),
                color: colors.get(PointKpi.Sufficient),
                strokeWidth: 10,
                ranges: kpiRanges.get(type)?.get(PointKpi.Sufficient),
            },
            {
                title: locale._(
                    `${nameof<typeof translations>('chartWidget')}.${PointKpi[PointKpi.Moderate].toLowerCase()}`
                ),
                gradient: PointKpi[PointKpi.Moderate].toLowerCase(),
                color: colors.get(PointKpi.Moderate),
                strokeWidth: 10,
                ranges: kpiRanges.get(type)?.get(PointKpi.Moderate),
            },
            {
                title: locale._(
                    `${nameof<typeof translations>('chartWidget')}.${PointKpi[PointKpi.InSufficient].toLowerCase()}`
                ),
                gradient: PointKpi[PointKpi.InSufficient].toLowerCase(),
                color: colors.get(PointKpi.InSufficient),
                strokeWidth: 10,
                ranges: kpiRanges.get(type)?.get(PointKpi.InSufficient),
            },
            {
                title: locale._(`${nameof<typeof translations>('chartWidget')}.avg`, {
                    avg: avg?.y,
                    unit: units.get(type),
                }),
                color: 'url(#circle)',
                strokeWidth: 10,
            },
        ]
    }, [locale, type, avg?.y])

    useEffect(() => {
        if (data?.length > 0) {
            let points = data

            if (predictions) {
                points = data.concat(predictions || []).sort((a, b) => a.x - b.x)
            }

            const x0 = points[0].x
            const xN = points[points.length - 1].x

            const y = points.map(e => e.y).sort((a, b) => a - b)
            if (y?.length > 0) {
                const areas: { gradient: string; data: AreaSeriesPoint[] }[] = []

                const yMin =
                    Math.trunc(
                        Math.min(
                            displayRanges.get(type)?.[0][0] || y[0],
                            Math.max(y[0], displayRanges.get(type)?.[1][0] || y[0])
                        ) / (displayRanges.get(type)?.[2][0] || 10)
                    ) * (displayRanges.get(type)?.[2][0] || 10)

                const yMax =
                    Math.ceil(
                        Math.max(
                            displayRanges.get(type)?.[0][1] || y[y.length - 1],
                            Math.min(y[y.length - 1], displayRanges.get(type)?.[1][1] || y[y.length - 1])
                        ) / (displayRanges.get(type)?.[2][0] || 10)
                    ) * (displayRanges.get(type)?.[2][0] || 10)

                setYDomain([yMin, yMax])

                legend.forEach(area => {
                    area.ranges?.forEach((range, index) => {
                        const value = {
                            y: Math.max(range[0], yMin),
                            y0: Math.min(range[1], yMax),
                        }

                        areas.push({
                            gradient: `${area.gradient}_${index}`,
                            data: [
                                { x: x0, ...value },
                                { x: xN, ...value },
                            ],
                        })
                    })
                })

                setAreas(areas)

                const avg = (arr => arr.reduce((p, c) => p + c, 0) / arr.length)(y)

                setAvg({ x: x0, y: parseFloat(avg.toFixed(trunc.get(type))) })
            }
        }
    }, [data, predictions, legend, type])

    const wd = useWindowDimensions()

    return (
        <Fragment>
            <div className={styles.legends}>
                <DiscreteColorLegend className={styles.legent} orientation="horizontal" items={legend} />
                {predictions && (
                    <DiscreteColorLegend
                        className={classNames(styles.legent, {
                            [styles.extraMarginLeft]: true,
                        })}
                        orientation="horizontal"
                        items={[
                            {
                                title: locale._(pathof<typeof translations>()._('chartWidget')._('prediction').path),
                                color: '#10264d',
                                strokeWidth: 3,
                                strokeDasharray: '2, 2',
                            } as {
                                title: string
                                gradient?: string
                                color?: string
                                strokeWidth: number
                                strokeDasharray?: string
                            },
                        ]}
                    />
                )}
            </div>
            <XYPlot
                xType={'time'}
                width={width || (isMobile ? (isPortrait ? wd.width - 50 : 600) : 700)}
                height={height || (isMobile ? 400 : 500)}
                onMouseLeave={() => setValue(undefined)}
                style={{ margin: 'auto' }}
                yDomain={yDomain}
            >
                <GradientDefs>
                    <linearGradient
                        id={`${PointKpi[PointKpi.Sufficient].toLowerCase()}_0`}
                        x1="0%"
                        x2="0%"
                        y1="100%"
                        y2="0%"
                    >
                        <stop offset="0%" stopColor={colors.get(PointKpi.Sufficient)} stopOpacity={0.1} />
                        <stop offset="50%" stopColor={colors.get(PointKpi.Sufficient)} stopOpacity={0.2} />
                        <stop offset="100%" stopColor={colors.get(PointKpi.Moderate)} stopOpacity={0.1} />
                    </linearGradient>
                    <linearGradient
                        id={`${PointKpi[PointKpi.Sufficient].toLowerCase()}_1`}
                        x1="0%"
                        x2="0%"
                        y1="100%"
                        y2="0%"
                    >
                        <stop offset="0%" stopColor={colors.get(PointKpi.Moderate)} stopOpacity={0.1} />
                        <stop offset="50%" stopColor={colors.get(PointKpi.Sufficient)} stopOpacity={0.2} />
                        <stop offset="100%" stopColor={colors.get(PointKpi.Sufficient)} stopOpacity={0.1} />
                    </linearGradient>
                    <linearGradient
                        id={`${PointKpi[PointKpi.Moderate].toLowerCase()}_0`}
                        x1="0%"
                        x2="0%"
                        y1="100%"
                        y2="0%"
                    >
                        <stop offset="0%" stopColor={colors.get(PointKpi.Moderate)} stopOpacity={0.1} />
                        <stop offset="50%" stopColor={colors.get(PointKpi.Moderate)} stopOpacity={0.2} />
                        <stop offset="100%" stopColor={colors.get(PointKpi.InSufficient)} stopOpacity={0.1} />
                    </linearGradient>
                    <linearGradient
                        id={`${PointKpi[PointKpi.Moderate].toLowerCase()}_1`}
                        x1="0%"
                        x2="0%"
                        y1="100%"
                        y2="0%"
                    >
                        <stop offset="0%" stopColor={colors.get(PointKpi.InSufficient)} stopOpacity={0.1} />
                        <stop offset="50%" stopColor={colors.get(PointKpi.Moderate)} stopOpacity={0.2} />
                        <stop offset="100%" stopColor={colors.get(PointKpi.Moderate)} stopOpacity={0.1} />
                    </linearGradient>
                    <linearGradient
                        id={`${PointKpi[PointKpi.InSufficient].toLowerCase()}_0`}
                        x1="0%"
                        x2="0%"
                        y1="100%"
                        y2="0%"
                    >
                        <stop offset="0%" stopColor={colors.get(PointKpi.InSufficient)} stopOpacity={0.1} />
                        <stop offset="100%" stopColor={colors.get(PointKpi.InSufficient)} stopOpacity={0.2} />
                    </linearGradient>
                    <linearGradient
                        id={`${PointKpi[PointKpi.InSufficient].toLowerCase()}_1`}
                        x1="0%"
                        x2="0%"
                        y1="100%"
                        y2="0%"
                    >
                        <stop offset="0%" stopColor={colors.get(PointKpi.InSufficient)} stopOpacity={0.2} />
                        <stop offset="100%" stopColor={colors.get(PointKpi.InSufficient)} stopOpacity={0.1} />
                    </linearGradient>
                    <pattern id="circle" x="4" y="11" width="15" height="15" patternUnits="userSpaceOnUse">
                        <circle cx="5" cy="5" r="5" fill="#10264d" />
                    </pattern>
                </GradientDefs>

                <XAxis
                    tickTotal={isMobile && isPortrait ? 5 : 10}
                    style={{
                        fontSize: 10,
                        line: { stroke: '#10264d' },
                        ticks: { fill: '#10264d', fontWeight: 500 },
                    }}
                    hideLine={true}
                    tickFormat={d3Locale}
                />
                <YAxis
                    tickTotal={isMobile && isPortrait ? 5 : 10}
                    style={{
                        fontSize: 10,
                        line: { stroke: '#10264d' },
                        ticks: { fill: '#10264d', fontWeight: 500 },
                    }}
                    tickFormat={t => new Intl.NumberFormat(undefined, { maximumFractionDigits: 0 }).format(t)}
                    hideLine={true}
                />

                {areas?.map((area, index) => {
                    return <AreaSeries color={`url(#${area.gradient})`} data={area.data} key={`as_${index}`} />
                })}

                <LineSeries
                    data={data}
                    onNearestX={d => setValue(d)}
                    curve={'curveMonotoneX'}
                    color={'#10264d'}
                    style={{
                        strokeWidth: 3,
                    }}
                />

                {predictions && data && data.length > 0 && predictions.length > 0 && (
                    <LineSeries
                        data={[data[data.length - 1]].concat(predictions.filter(p => p.x > data[data.length - 1].x))}
                        onNearestX={d => d.x > data[data.length - 1].x && d.x > predictions[0].x && setValue(d)}
                        curve={'curveMonotoneX'}
                        color={'#10264d'}
                        style={{
                            strokeWidth: 3,
                            strokeDasharray: '2, 2',
                        }}
                    />
                )}

                {avg && (
                    <CustomSVGSeries
                        data={[
                            {
                                customComponent: 'circle',
                                style: { fill: '#10264d' },
                                size: 10,
                                ...avg,
                            },
                        ]}
                    />
                )}

                {value && (
                    <Crosshair
                        className={styles.crosshair}
                        values={[value]}
                        titleFormat={() => {
                            return {
                                title: locale._(translations.dataOverview.timeTitle),
                                value: locale._(translations.dataOverview.timeValue, {
                                    date: dateFormat(value.x, 'd mmmm yyyy').toLowerCase(),
                                    time: dateFormat(value.x, 'HH:MM'),
                                }),
                            }
                        }}
                        itemsFormat={() => {
                            return [
                                {
                                    title: locale._(`${pathof<typeof translations>()._('dataOverview').path}.${type}`),
                                    value: `${value.y} ${units.get(type)}`,
                                },
                            ]
                        }}
                        style={{
                            line: { backgroundColor: '#fff' },
                            box: { backgroundColor: '#fff', borderColor: '#d7d7d7' },
                        }}
                    />
                )}
            </XYPlot>
        </Fragment>
    )
}

const Graph = withOrientationChange(GraphInternal)

export { Graph }
