import { Type, Widget } from '@unicaiot/unica-iot-gallery-airinsight'
import React, { FunctionComponent } from 'react'
import { BuildingsOverview } from '../../../DataExplorer/components/BuildingsOverview/BuildingsOverview'
import styles from './BuildingsOverviewWidget.module.scss'
import { ISensor, PointKpi } from '../../../DataExplorer/services/types'

export interface Props {
    onClick?: (sensors?: ISensor[]) => void
    sensors?: ISensor[]
    restoraton?: string[]
    type: Type
    kpiType?: PointKpi
}

export const BuildingsOverviewWidget: FunctionComponent<Props> = ({ onClick, type, kpiType, sensors, restoraton }) => {
    const onCompareAdd = (sensor: ISensor) => {
        sensors = sensors || []

        const index = sensors.findIndex(s => s.id === sensor.id)
        if (index > -1) {
            sensors.splice(index, 1)
        } else {
            if (sensors.length >= 3) {
                return
            }

            sensors.push(sensor)
        }

        onClick && onClick(sensors)
    }

    return (
        <Widget className={styles.widget} borders={false}>
            <BuildingsOverview
                type={type}
                editEnable={true}
                compareEnabled={true}
                isExpanded={false}
                sensors={sensors}
                restoration={restoraton}
                onCompareAdd={sensor => {
                    onCompareAdd(sensor)
                }}
                onClick={sensor => {
                    onClick && onClick([sensor])
                }}
                onRestore={sensors => {
                    onClick && onClick(sensors)
                }}
                kpiType={kpiType}
            />
        </Widget>
    )
}
