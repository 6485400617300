import React, { Fragment, FunctionComponent, useContext } from 'react'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext, useUser } from '@unicaiot/unica-iot-gallery-core'
import styles from './AnnouncmentWidget.module.scss'
import {
    AnnouncementPreferenceKey,
    HeadingLevel,
    Title,
    useGetCombinedPreference,
    Widget,
} from '@unicaiot/unica-iot-gallery-airinsight'
import { translations } from '../../../../translations'

export const AnnouncmentWidget: FunctionComponent = () => {
    const user = useUser()
    const locale = useContext(LocaleContext)

    const announcement = useGetCombinedPreference<string>(user?.sub, AnnouncementPreferenceKey)

    return (
        <Widget className={styles.widget}>
            <Fragment>
                <Title className={styles.title} headingLevel={HeadingLevel.h1}>
                    {locale._(translations.announcmentWidget.title)}
                </Title>
                {!user || announcement.loading ? (
                    <ActivityIndicator className={styles.info} size={ActivityIndicatorEnumType.small} />
                ) : (
                    <div
                        className={styles.info}
                        dangerouslySetInnerHTML={{
                            __html:
                                announcement?.data?.[AnnouncementPreferenceKey] ||
                                `<i>${locale._(translations.announcmentWidget.info)}</i>`,
                        }}
                    />
                )}
            </Fragment>
        </Widget>
    )
}
