import { Building, HData, Kpi, PointKpi, Room, Type } from './types'
import { explorerService } from './service'
import { RequestStatus, useRequest } from '@unicaiot/unica-iot-gallery-core'

export const useGetOverviewAllCounts = (type?: Type, kpiType?: PointKpi): RequestStatus<Kpi | undefined> => {
    const getProsmise = () => (type ? explorerService.getOverviewAllCounts(type, kpiType) : undefined)

    return useRequest(getProsmise, [type, kpiType])
}

export const useGetOverview = (
    type?: Type,
    kpiType?: PointKpi,
    exclude?: boolean
): RequestStatus<Building[] | undefined> => {
    const getProsmise = () => (type ? explorerService.getOverview(type, kpiType, exclude) : undefined)

    return useRequest(getProsmise, [type, kpiType, exclude])
}

export const useGetData = (id: string, startDate: Date, endDate: Date): RequestStatus<HData | undefined> => {
    const getProsmise = () => explorerService.getData(id, startDate, endDate)

    return useRequest(getProsmise, [id, startDate, endDate])
}

export const useGetPredictions = (id?: string): RequestStatus<HData | undefined> => {
    const getProsmise = () => (id ? explorerService.getPredictions(id) : undefined)

    return useRequest(getProsmise, [id])
}

export const useGetDatas = (ids: string[], startDate: Date, endDate: Date): RequestStatus<HData[] | undefined> => {
    const getPromise = () =>
        ids
            ? Promise.all(ids.map(id => explorerService.getData(id, startDate, endDate))).then(r => {
                  return {
                      data: r.map(e => {
                          return e.data
                      }),
                      status: 200,
                      statusText: 'OK',
                      headers: {},
                      config: {},
                  }
              })
            : undefined

    return useRequest(getPromise, [ids, startDate, endDate])
}

export const useGetRoom = (pointRef?: string): RequestStatus<Room | undefined> => {
    const getPromise = () => (pointRef ? explorerService.getRoom(pointRef) : undefined)

    return useRequest(getPromise, [pointRef])
}

export const useGetBuilding = (pointRef?: string): RequestStatus<Building | undefined> => {
    const getPromise = () => (pointRef ? explorerService.getBuilding(pointRef) : undefined)

    return useRequest(getPromise, [pointRef])
}

export const useGetRooms = (pointRefs?: string[]): RequestStatus<Room[] | undefined> => {
    const getPromise = () =>
        pointRefs
            ? Promise.all(pointRefs.map(pointRef => explorerService.getRoom(pointRef))).then(r => {
                  return {
                      data: r.map(e => {
                          return e.data
                      }),
                      status: 200,
                      statusText: 'OK',
                      headers: {},
                      config: {},
                  }
              })
            : undefined

    return useRequest(getPromise, [pointRefs])
}

export const useGetBuildings = (pointRefs?: string[]): RequestStatus<Building[] | undefined> => {
    const getPromise = () =>
        pointRefs
            ? Promise.all(pointRefs.map(pointRef => explorerService.getBuilding(pointRef))).then(r => {
                  return {
                      data: r.map(e => {
                          return e.data
                      }),
                      status: 200,
                      statusText: 'OK',
                      headers: {},
                      config: {},
                  }
              })
            : undefined

    return useRequest(getPromise, [pointRefs])
}
