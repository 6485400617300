import React, { FunctionComponent, useState, useEffect, Fragment, useContext } from 'react'
import styles from './Explorer.module.scss'
import { useGetData } from '../../services/hooks'
import '../../../../../node_modules/react-vis/dist/style.css'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../Common/datepicker.css'
import {
    PageError,
    ActivityIndicator,
    ActivityIndicatorEnumType,
    useWindowDimensions,
    LocaleContext,
} from '@unicaiot/unica-iot-gallery-core'
import { Graph } from './Graph'
import { isMobileOnly } from 'react-device-detect'
import { Type } from '../../services/types'
import { translations } from '../../../../translations'

export interface Props {
    id: string
    type: Type
}

export const Explorer: FunctionComponent<Props> = ({ id, type }) => {
    const locale = useContext(LocaleContext)

    const [data, setData] = useState<{ x: number; y: number }[]>()

    const addDays = (date: Date, days: number) => {
        date.setDate(date.getDate() + days)
        return date
    }

    const [startDate, setStartDate] = useState<Date>(addDays(new Date(), -1))
    const [endDate, setEndDate] = useState<Date>(new Date())

    const dResult = useGetData(id, startDate, endDate)

    if (dResult?.error) {
        throw new PageError(dResult.error)
    }

    useEffect(() => {
        if (dResult.data) {
            setData(
                dResult.data?.rows
                    .map(r => {
                        return {
                            x: Date.parse(r.ts.substring(2).split(' ')[0]),
                            y: Number.parseFloat(r.val.substring(2)),
                        }
                    })
                    .sort((a, b) => {
                        let comparison = 0
                        if (a.x > b.x) {
                            comparison = 1
                        } else if (a.x < b.x) {
                            comparison = -1
                        }
                        return comparison
                    })
            )
        }
    }, [dResult.data])

    const wd = useWindowDimensions()

    const width = isMobileOnly ? wd.width - 2 * 1 * 8 : wd.width - 2 * 6 * 8
    const height = isMobileOnly ? wd.height - 65 - 1 * 5 * 8 - 26 * 8 : wd.height - 65 - 2 * 8 * 5 - 27 * 8

    return (
        <Fragment>
            <div className={styles.dpContainer}>
                <div className={styles.inputContainer}>
                    <span className={styles.label}>{locale._(translations.filters.periodLabel)}</span>
                    <DatePicker
                        className={styles.dp}
                        required={true}
                        selected={startDate}
                        onChange={(date: Date | null) => date && setStartDate(date)}
                        selectsStart={true}
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={endDate}
                        dateFormat="d MMMM yyyy"
                        wrapperClassName={styles.dpWrapper}
                    />
                    <span className={styles.label}>{locale._(translations.filters.tillLabel)}</span>
                    <DatePicker
                        className={styles.dp}
                        popperPlacement="bottom-end"
                        required={true}
                        selected={endDate}
                        onChange={(date: Date | null) => date && setEndDate(date)}
                        selectsEnd={true}
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                        maxDate={new Date()}
                        dateFormat="d MMMM yyyy"
                        wrapperClassName={styles.dpWrapper}
                    />
                </div>
            </div>
            <div className={styles.chartContainer}>
                {dResult.loading || !data ? (
                    <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
                ) : (
                    <Graph data={data} width={width} height={height} type={type} />
                )}
            </div>
        </Fragment>
    )
}
