import { Config as ConfigGallery, config as configGallery } from '@unicaiot/unica-iot-gallery-core'

export interface Config extends ConfigGallery {
    mapping: (code: string) => string
    registration: (id: string) => string
    rooms: string
    kpi: {
        getAllOverviewCounts: string
        getAllOverview: string
    }
    buildings: string
    sensors: (id: string) => string
    hdata: {
        get: string
    }
    hpredictions: {
        get: string
    }
    defaultLocale: string
    preferences: {
        preferencesCombined: (userId: string) => string
    }
    export: string
    entities: (type: 'rooms' | 'buildings', id: string) => string

    pbiEmbed: {
        embedParams: (workspaceId: string, reportId: string) => string
        worksapces: string
        worksapcesExist: string
    }
}

export const config: Config = {
    ...configGallery,
    mapping: (code: string) => `/mapping/co2/${code}`,
    registration: (id: string) => `/api/registration/${id}`,
    rooms: '/api/rooms',
    kpi: {
        getAllOverviewCounts: 'api/kpi/counts',
        getAllOverview: 'api/kpi/all',
    },
    buildings: 'api/buildings',
    sensors: (id: string) => `/api/sensors/${id}`,
    hdata: {
        get: '/hs/hisRead',
    },
    hpredictions: {
        get: '/hs/predictionsRead',
    },
    defaultLocale: 'nl',
    preferences: {
        preferencesCombined: (userId: string) => `p/preferences/fetch/${userId}`,
    },
    export: 'reports/csv/raw',
    entities: (type: 'rooms' | 'buildings', id: string) => `api/${type}/${id}`,
    pbiEmbed: {
        embedParams: (workspaceId: string, reportId: string) =>
            `api/pbiembed/workspaces/${workspaceId}/reports/${reportId}`,
        worksapces: 'api/pbiembed/workspaces',
        worksapcesExist: 'api/pbiembed/workspaces/exist',
    },
}
