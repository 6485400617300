import React, { Fragment, FunctionComponent, useContext } from 'react'
import { LocaleContext, useUser } from '@unicaiot/unica-iot-gallery-core'
import styles from './WelcomeWidget.module.scss'
import { FontSize, HeadingLevel, Title, Widget } from '@unicaiot/unica-iot-gallery-airinsight'
import { translations } from '../../../../translations'

export const WelcomeWidget: FunctionComponent = () => {
    const user = useUser()
    const locale = useContext(LocaleContext)

    return (
        <Widget className={styles.widget}>
            <Fragment>
                <Title className={styles.title} headingLevel={HeadingLevel.h1}>
                    {locale._(translations.welcomeWidget.title, { name: `${user?.given_name} ${user?.family_name}` })}
                </Title>
                <Title className={styles.subTitle} fontSize={FontSize.size14} headingLevel={HeadingLevel.h3}>
                    {locale._(translations.welcomeWidget.subTitle)}
                </Title>
            </Fragment>
        </Widget>
    )
}
