import {
    Auth,
    DisabledTypesPreferenceKey,
    FontSize,
    HeadingLevel,
    PageLayout,
    Title,
    useGetCombinedPreference,
} from '@unicaiot/unica-iot-gallery-airinsight'
import { LocaleContext, PageError, pathof, useUser } from '@unicaiot/unica-iot-gallery-core'
import React, { Fragment, FunctionComponent, useContext, useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { routes } from '../../../../routes'
import { translations } from '../../../../translations'
import { Explorer } from '../../components/Explorer/Explorer'
import { extractInfo } from '../../components/Helpers/SensorHelper'
import { useGetBuilding } from '../../services/hooks'
import { Type } from '../../services/types'
import styles from './ExplorerView.module.scss'

export interface Props {
    id: string
    type: string
}

const ExplorerLayout: FunctionComponent<{ id: string; type: Type }> = ({ id, type }) => {
    const locale = useContext(LocaleContext)

    const bResult = useGetBuilding(id)

    if (bResult?.error) {
        throw new RangeError(bResult.error)
    }

    const info = useMemo(() => {
        return extractInfo(bResult.data)
    }, [bResult.data])

    const user = useUser()
    const pResult = useGetCombinedPreference<Type[]>(user?.sub, DisabledTypesPreferenceKey)

    if (pResult.error || pResult.data?.[DisabledTypesPreferenceKey]?.includes(type)) {
        throw new PageError(`${type} is disabled.`)
    }

    return (
        <PageLayout
            loading={!user || bResult.loading || !bResult.data || pResult.loading}
            title={
                <Fragment>
                    <Title className={styles.titlePlace} headingLevel={HeadingLevel.h1}>
                        {info.path}
                    </Title>
                    <Title className={styles.titleSensor} headingLevel={HeadingLevel.h1}>
                        {info.name}
                    </Title>
                </Fragment>
            }
            subTitle={
                <Title className={styles.subTitle} fontSize={FontSize.size14} headingLevel={HeadingLevel.h2}>
                    {locale._(translations.explorerView.subTitle, {
                        type: locale._(`${pathof<typeof translations>()._('dataOverview').path}.${type}`),
                    })}
                </Title>
            }
            backRoute={routes.dashboard}
            content={() => <Explorer id={id} type={type} />}
            contentClassName={styles.content}
            showFooter={true}
            showHeader={true}
            fullScreen={true}
        />
    )
}

export const ExplorerView: FunctionComponent<RouteComponentProps<Props>> = props => {
    const type = Object.values(Type).find(k => k === props.match.params.type.toLowerCase())

    if (type === undefined) {
        throw new PageError(`${props.match.params.type} is not supported.`)
    }

    return (
        <Auth>
            <ExplorerLayout id={props.match.params.id} type={type} />
        </Auth>
    )
}
