import {
    Button,
    ButtonStyleType,
    Column,
    DisabledTypesPreferenceKey,
    ErrorLayout,
    Icon,
    Icons,
    Row,
    RowLayout,
    Select,
    TextButton,
    Type,
    useGetCombinedPreference,
} from '@unicaiot/unica-iot-gallery-airinsight'
import React, { Fragment, FunctionComponent, useContext, useMemo, useRef, useState } from 'react'
import styles from './DataOverviewLayout.module.scss'
import './DataOverviewLayoutTabs.extensions.scss'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
    LocaleContext,
    pathof,
    storageService,
    useUser,
} from '@unicaiot/unica-iot-gallery-core'
import { translations } from '../../../../translations'
import { ISensor, PointKpi } from '../../../DataExplorer/services/types'
import { CompareGraphWidget } from '../../../DataExplorer/components/CompareGraphWidget/CompareGraphWidget'
import { GraphWidget } from '../../../DataExplorer/components/GraphWidget/GraphWidget'
import { ChartWidget } from '../../../DataExplorer/components/ChartWidget/ChartWidget'
import { BuildingsOverviewWidget } from '../BuildingsOverviewWidget/BuildingsOverviewWidget'
import classNames from 'classnames'
import { Trans } from '@lingui/react'
import { useHistory } from 'react-router-dom'
import { routes } from '../../../../routes'
import { stringify } from 'query-string'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../../DataExplorer/components/Common/datepicker.css'
import { useCheckEmbedWorkspaces } from '../../services/hooks'

const typeLastViewedKey = 'type_last_viewed'
const kpiLastSelectedKey = 'kpi_last_selected'
const sensorsLastSelectedKey = 'sensors_last_selected'

export const DataOverviewLayout: FunctionComponent = () => {
    const [sensors, setSensors] = useState(storageService.get<ISensor[]>(sensorsLastSelectedKey) || undefined)
    const [restoration, setRestoratione] = useState<string[]>()
    const [type, setType] = useState<Type>(storageService.get<Type>(typeLastViewedKey) || Type.CO2)
    const [selectedKpi, setSelectedKpi] = useState(storageService.get<PointKpi>(kpiLastSelectedKey) || undefined)

    const addHours = (date: Date, hours: number) => {
        date.setHours(date.getHours() + hours)
        return date
    }

    const [latest, setLatest] = useState(true)
    const [latestButton, setLatestButton] = useState(false)

    const [startDate, setStartDate] = useState<Date>(addHours(new Date(), -1))
    const [endDate, setEndDate] = useState<Date>(new Date())

    const [index, setIndex] = useState(0)

    const locale = useContext(LocaleContext)

    const user = useUser()
    const result = useGetCombinedPreference<Type[]>(user?.sub, DisabledTypesPreferenceKey)

    const pbiResult = useCheckEmbedWorkspaces()

    const types = useMemo(() => {
        return result.data
            ? Object.values(Type).filter(t => !result.data?.[DisabledTypesPreferenceKey]?.includes(t))
            : []
    }, [result.data])

    const onSetType = (type: Type) => {
        setType(type)
        storageService.set(typeLastViewedKey, type)

        setSelectedKpi(storageService.get<PointKpi>(kpiLastSelectedKey) || undefined)

        setIndex(index + 1)
    }

    const onSelectKpi = (kpi?: PointKpi) => {
        setSelectedKpi(kpi)
        if (kpi) {
            storageService.set(kpiLastSelectedKey, kpi)
        } else {
            storageService.remove(kpiLastSelectedKey)
        }
    }

    const onSetSensors = (newSensors?: ISensor[]) => {
        setIndex(index + 1)

        setSensors(newSensors)
        setRestoratione(undefined)

        if (newSensors) {
            storageService.set(sensorsLastSelectedKey, newSensors)
        } else {
            storageService.remove(sensorsLastSelectedKey)
        }
    }

    const onTabClick = (index: number) => {
        onSetType(types[index])
        setSelectedKpi(storageService.get<PointKpi>(kpiLastSelectedKey) || undefined)
        setRestoratione(sensors?.map(s => s.identifier))
        setSensors(undefined)
        setIndex(index + 1)
    }

    const history = useHistory()
    const periodRef = useRef<HTMLDivElement>(null)

    const onCalendarOpen = () => {
        const latestButtons = periodRef.current?.getElementsByClassName('react-datepicker__today-button')

        for (const latestButton of Array.from(latestButtons || [])) {
            latestButton.addEventListener('click', () => {
                setLatestButton(true)
            })
        }
    }

    return (
        <div className={styles.container}>
            {!user?.sub || !result.data ? (
                <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
            ) : result.error ? (
                <ErrorLayout />
            ) : (
                <Tabs selectedIndex={types.indexOf(type)} onSelect={onTabClick}>
                    {
                        <TabList>
                            {types.map((t, i) => {
                                return (
                                    <Tab key={`tab_${i}`}>
                                        {locale._(`${pathof<typeof translations>()._('dataOverview').path}.${t}`)}
                                    </Tab>
                                )
                            })}
                        </TabList>
                    }
                    {types.map((t, i) => {
                        return (
                            <TabPanel key={`tabpenel_${i}`}>
                                <RowLayout className={styles.filters}>
                                    <Column largeSpacing={true}>
                                        <RowLayout className={styles.rowLayout}>
                                            <Row className={styles.statusFilter}>
                                                <span className={styles.statusLabel}>
                                                    {locale._(translations.filters.selectStatusLabel)}
                                                </span>
                                                <span className={styles.statusSelect}>
                                                    <span>
                                                        {selectedKpi
                                                            ? locale._(
                                                                  `${
                                                                      pathof<typeof translations>()._('chartWidget')
                                                                          .path
                                                                  }.${PointKpi[selectedKpi].toLocaleLowerCase()}`
                                                              )
                                                            : locale._(translations.filters.selectStatusAll)}
                                                    </span>
                                                    <Select
                                                        value={selectedKpi && PointKpi[selectedKpi]}
                                                        required={false}
                                                        keyPref={'status'}
                                                        onChange={v => {
                                                            onSelectKpi(
                                                                Object.values(PointKpi).find(t => PointKpi[t] === v)
                                                            )
                                                            onSetSensors(undefined)
                                                        }}
                                                        options={[
                                                            {
                                                                value: '',
                                                                name: locale._(translations.filters.selectStatusAll),
                                                            },
                                                        ].concat(
                                                            Object.values(PointKpi)
                                                                .filter(k => k !== PointKpi.Unknown)
                                                                .map(t => {
                                                                    return {
                                                                        value: t,
                                                                        name: locale._(
                                                                            `${
                                                                                pathof<typeof translations>()._(
                                                                                    'chartWidget'
                                                                                ).path
                                                                            }.${PointKpi[t].toLocaleLowerCase()}`
                                                                        ),
                                                                    }
                                                                })
                                                        )}
                                                    />
                                                    <Icon
                                                        className={styles.triangle}
                                                        icon={Icons.triangle}
                                                        size={[8, 5]}
                                                    />
                                                </span>
                                            </Row>
                                            <Button
                                                className={styles.exportButton}
                                                buttonStyle={ButtonStyleType.secondary}
                                                icon={Icons.export}
                                                onClick={() => history.push(routes.export(type))}
                                            >
                                                <Trans
                                                    id="dataOverviewlayout.goToExport"
                                                    message="Ga naar de exportpagina"
                                                />
                                            </Button>
                                            {pbiResult.data && (
                                                <Button
                                                    className={styles.exportButton}
                                                    buttonStyle={ButtonStyleType.secondary}
                                                    icon={Icons.export}
                                                    onClick={() => history.push(routes.powerBiWorkspaces)}
                                                >
                                                    <Trans
                                                        id="dataOverviewlayout.goToPbi"
                                                        message="Ga naar de pbi rapporten"
                                                    />
                                                </Button>
                                            )}
                                        </RowLayout>
                                    </Column>
                                    <Column largeSpacing={true}>
                                        <RowLayout className={styles.rowLayout}>
                                            {sensors && sensors.length > 0 && (
                                                <Fragment>
                                                    <div ref={periodRef} className={styles.dateFilter}>
                                                        <span className={styles.dateLabel}>
                                                            {locale._(translations.filters.periodLabel)}
                                                        </span>
                                                        <DatePicker
                                                            className={styles.dp}
                                                            required={true}
                                                            selected={startDate}
                                                            onChange={(date: Date | null) => {
                                                                date && setStartDate(date)
                                                                date && setLatest(latestButton)
                                                                date && latestButton && setEndDate(date)
                                                                date && setLatestButton(false)
                                                            }}
                                                            selectsStart={true}
                                                            onCalendarOpen={onCalendarOpen}
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            maxDate={endDate}
                                                            dateFormat="d MMMM yyyy"
                                                        />
                                                        <span className={styles.dateLabel}>
                                                            {locale._(translations.filters.tillLabel)}
                                                        </span>
                                                        <DatePicker
                                                            className={styles.dp}
                                                            popperPlacement="bottom-end"
                                                            required={true}
                                                            selected={endDate}
                                                            onChange={(date: Date | null) => {
                                                                date && setEndDate(date)
                                                                date && setLatest(latestButton)
                                                                date && latestButton && setStartDate(date)
                                                                date && setLatestButton(false)
                                                            }}
                                                            onCalendarOpen={onCalendarOpen}
                                                            selectsEnd={true}
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            minDate={startDate}
                                                            maxDate={new Date()}
                                                            dateFormat="d MMMM yyyy"
                                                            todayButton={locale._(translations.filters.latestLabel)}
                                                        />
                                                    </div>
                                                    <Button
                                                        className={styles.fullButton}
                                                        buttonStyle={ButtonStyleType.secondary}
                                                        icon={Icons.full}
                                                        onClick={() => {
                                                            if (sensors.length === 1) {
                                                                history.push(routes.explorer(type, sensors[0].id))
                                                            } else {
                                                                history.push({
                                                                    pathname: routes.comparer(type),
                                                                    search: stringify({ ids: sensors.map(s => s.id) }),
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <Trans
                                                            id="dataOverviewlayout.goToFullPage"
                                                            message="Breid de grafiek uit"
                                                        />
                                                    </Button>
                                                </Fragment>
                                            )}
                                        </RowLayout>
                                    </Column>
                                </RowLayout>
                                <RowLayout>
                                    <Column largeSpacing={true}>
                                        <BuildingsOverviewWidget
                                            key={`do_${index}_${i}`}
                                            type={t}
                                            sensors={sensors}
                                            restoraton={restoration}
                                            onClick={sensors => {
                                                setSensors(Object.assign([], sensors))
                                                storageService.set(sensorsLastSelectedKey, sensors)
                                                setRestoratione(undefined)
                                                setStartDate(addHours(new Date(), -1))
                                                setEndDate(new Date())
                                                setLatest(true)
                                            }}
                                            kpiType={selectedKpi}
                                        />
                                    </Column>
                                    <Column largeSpacing={true}>
                                        <TextButton
                                            className={classNames(styles.backButton, {
                                                [styles.isHidden]: (!sensors || sensors.length <= 0) && !selectedKpi,
                                            })}
                                            onClick={() => {
                                                onSelectKpi(undefined)
                                                onSetSensors(undefined)
                                            }}
                                            buttonStyle={ButtonStyleType.tertiary}
                                        >
                                            {locale._(translations.dataOverviewLayout.back)}
                                        </TextButton>
                                        {sensors && sensors.length > 0 ? (
                                            sensors.length > 1 ? (
                                                <CompareGraphWidget
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    key={`cgw_${index}`}
                                                    sensors={sensors}
                                                    type={type}
                                                    latest={latest}
                                                />
                                            ) : (
                                                <GraphWidget
                                                    latest={latest}
                                                    startDate={startDate}
                                                    endDate={endDate}
                                                    key={`cw_${index}`}
                                                    id={sensors[0].id}
                                                    type={type}
                                                />
                                            )
                                        ) : (
                                            <ChartWidget key={`cw_${index}`} type={type} kpiType={selectedKpi} />
                                        )}
                                    </Column>
                                </RowLayout>
                            </TabPanel>
                        )
                    })}
                </Tabs>
            )}
        </div>
    )
}
