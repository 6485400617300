import {
    Auth,
    BadgeIcon,
    DisabledTypesPreferenceKey,
    Icon,
    Icons,
    LayoutType,
    PageLayout,
    TextButton,
    useGetCombinedPreference,
} from '@unicaiot/unica-iot-gallery-airinsight'
import {
    ActivityIndicator,
    ActivityIndicatorEnumType,
    LocaleContext,
    PageError,
    pathof,
    storageService,
    useUser,
} from '@unicaiot/unica-iot-gallery-core'
import React, { Fragment, FunctionComponent, useContext, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { routes } from '../../../../routes'
import { translations } from '../../../../translations'
import { BuildingsOverview } from '../../components/BuildingsOverview/BuildingsOverview'
import { ISensor, Type } from '../../services/types'
import styles from './DataExportView.module.scss'
import { explorerService } from '../../services/service'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../components/Common/datepicker.css'

const exportSensorsKey = 'export_sensors_'

const DataExportLayout: FunctionComponent<{ type: Type }> = ({ type }) => {
    const [isLoading, setIsLoading] = useState(false)

    const addDays = (date: Date, days: number) => {
        date.setDate(date.getDate() + days)
        return date
    }

    const [startDate, setStartDate] = useState<Date>(addDays(new Date(), -1))
    const [endDate, setEndDate] = useState<Date>(new Date())

    const locale = useContext(LocaleContext)

    const user = useUser()
    const result = useGetCombinedPreference<Type[]>(user?.sub, DisabledTypesPreferenceKey)

    if (result.error || result.data?.[DisabledTypesPreferenceKey]?.includes(type)) {
        throw new PageError(`${type} is disabled.`)
    }

    const [sensors, setSensors] = useState<ISensor[]>(storageService.get<ISensor[]>(`${exportSensorsKey}${type}`) || [])

    const onCompareAdd = (sensor: ISensor) => {
        const index = sensors.findIndex(s => s.id === sensor.id)
        if (index > -1) {
            sensors.splice(index, 1)
        } else {
            sensors.push(sensor)
        }

        setSensors(Object.assign([], sensors))
    }

    useEffect(() => {
        storageService.set<{ id: string; name: string }[]>(`${exportSensorsKey}${type}`, sensors)
    }, [sensors, type])

    const onExport = async () => {
        setIsLoading(true)

        const result = await explorerService.postExport(
            sensors.map(s => s.id),
            startDate,
            endDate
        )

        const tempLink = document.createElement('a')
        tempLink.href = window.URL.createObjectURL(new Blob([result.data], { type: result.headers['content-type'] }))

        const filename = result.headers['content-disposition']
            .split(';')
            .find((n: string) => n.includes('filename='))
            .replace('filename=', '')
            .trim()

        tempLink.setAttribute('download', filename)
        tempLink.click()

        setIsLoading(false)
    }

    return (
        <PageLayout
            pageType={LayoutType.light}
            title={`${locale._(translations.dataExportView.title)} (${locale._(
                `${pathof<typeof translations>()._('dataOverview').path}.${type}`
            )})`}
            loading={!user || result.loading}
            backRoute={routes.dashboard}
            content={() => (
                <Fragment>
                    <div className={styles.dpContainer}>
                        <div className={styles.inputContainer}>
                            <span className={styles.label}>{locale._(translations.filters.periodLabel)}</span>
                            <DatePicker
                                className={styles.dp}
                                required={true}
                                selected={startDate}
                                onChange={(date: Date | null) => date && setStartDate(date)}
                                selectsStart={true}
                                startDate={startDate}
                                endDate={endDate}
                                maxDate={endDate}
                                dateFormat="d MMMM yyyy"
                                wrapperClassName={styles.dpWrapper}
                            />
                            <span className={styles.label}>{locale._(translations.filters.tillLabel)}</span>
                            <DatePicker
                                className={styles.dp}
                                popperPlacement="bottom-end"
                                required={true}
                                selected={endDate}
                                onChange={(date: Date | null) => date && setEndDate(date)}
                                selectsEnd={true}
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                maxDate={new Date()}
                                dateFormat="d MMMM yyyy"
                                wrapperClassName={styles.dpWrapper}
                            />
                        </div>
                    </div>
                    <div className={styles.compare}>
                        <TextButton
                            className={styles.compareButton}
                            isDisabled={sensors.length <= 0 || isLoading}
                            onClick={onExport}
                        >
                            {isLoading ? (
                                <ActivityIndicator size={ActivityIndicatorEnumType.small} />
                            ) : (
                                <BadgeIcon icon={Icons.excel} size={[24, 24]} shift={2} badge={sensors.length} />
                            )}
                        </TextButton>
                        <TextButton onClick={() => setSensors([])} isDisabled={sensors.length <= 0}>
                            <Icon icon={Icons.reset} size={[24, 24]} />
                        </TextButton>
                    </div>
                    <BuildingsOverview
                        type={type}
                        compareEnabled={true}
                        sensors={sensors}
                        onCompareAdd={onCompareAdd}
                        isExpanded={true}
                        exclude={true}
                    />
                </Fragment>
            )}
            showFooter={true}
            showHeader={true}
        />
    )
}

export const DataExportView: FunctionComponent<
    RouteComponentProps<{
        type: string
    }>
> = props => {
    const type = useMemo(() => {
        return Object.values(Type).find(k => k === props.match.params.type.toLowerCase())
    }, [props.match.params.type])

    if (type === undefined) {
        throw new PageError(`${props.match.params.type} is not supported.`)
    }

    return (
        <Auth>
            <DataExportLayout type={type} />
        </Auth>
    )
}
