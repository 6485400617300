import {
    Auth,
    DisabledTypesPreferenceKey,
    FontSize,
    HeadingLevel,
    PageLayout,
    Title,
    useGetCombinedPreference,
} from '@unicaiot/unica-iot-gallery-airinsight'
import { LocaleContext, PageError, pathof, useUser } from '@unicaiot/unica-iot-gallery-core'
import React, { Fragment, FunctionComponent, useContext, useMemo } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { routes } from '../../../../routes'
import { translations } from '../../../../translations'
import { useGetBuildings } from '../../services/hooks'
import { Sensor, Type } from '../../services/types'
import styles from './ComparerView.module.scss'
import { parse } from 'query-string'
import { Comparer } from '../../components/Comparer/Comparer'
import { extractInfo } from '../../components/Helpers/SensorHelper'

const ComparerLayout: FunctionComponent<{ ids: string[]; type: Type }> = ({ ids, type }) => {
    const locale = useContext(LocaleContext)

    const bResult = useGetBuildings(ids)

    if (bResult?.error) {
        throw new RangeError(bResult.error)
    }

    const info = useMemo(() => {
        return (
            bResult.data?.map(extractInfo).reduce((a, c) => {
                return {
                    path: `${a.path} vs ${c.path}`,
                    name: `${a.name} vs ${c.name}`,
                }
            }) || {
                path: '',
                name: '',
            }
        )
    }, [bResult.data])

    const user = useUser()
    const pResult = useGetCombinedPreference<Type[]>(user?.sub, DisabledTypesPreferenceKey)

    if (pResult.error || pResult.data?.[DisabledTypesPreferenceKey]?.includes(type)) {
        throw new PageError(`${type} is disabled.`)
    }

    return (
        <PageLayout
            loading={!user || bResult.loading || !bResult.data || pResult.loading}
            title={
                <Fragment>
                    <Title className={styles.titlePlace} headingLevel={HeadingLevel.h1}>
                        {info.path}
                    </Title>
                    <Title className={styles.titleSensor} headingLevel={HeadingLevel.h1}>
                        {info.name}
                    </Title>
                </Fragment>
            }
            subTitle={
                <Title className={styles.subTitle} fontSize={FontSize.size14} headingLevel={HeadingLevel.h2}>
                    {locale._(translations.explorerView.subTitle, {
                        type: locale._(`${pathof<typeof translations>()._('dataOverview').path}.${type}`),
                    })}
                </Title>
            }
            backRoute={routes.dashboard}
            content={() =>
                bResult.data ? (
                    <Comparer
                        sensors={bResult.data
                            .map(b => b?.rooms?.[0].sensors?.[0])
                            .reduce<Sensor[]>((r, c) => {
                                c && r.push(c)
                                return r
                            }, [])
                            .map(s => {
                                return {
                                    id: s.id,
                                    name: s.name,
                                }
                            })}
                        type={type}
                    />
                ) : (
                    <Fragment />
                )
            }
            contentClassName={styles.content}
            showFooter={true}
            showHeader={true}
            fullScreen={true}
        />
    )
}

export interface Props {
    type: string
}

export const ComparerView: FunctionComponent<RouteComponentProps<Props>> = props => {
    const type = Object.values(Type).find(k => k === props.match.params.type.toLowerCase())

    if (type === undefined) {
        throw new PageError(`${props.match.params.type} is not supported.`)
    }

    const ids = parse(props.location.search)['ids']

    if (ids === null || typeof ids === 'string') {
        throw new PageError('An array of ids is expected.')
    }

    return (
        <Auth>
            <ComparerLayout ids={ids} type={type} />
        </Auth>
    )
}
