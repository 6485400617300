import { routes as routesGallery } from '@unicaiot/unica-iot-gallery-core'

export const routes = {
    ...routesGallery,
    explorer: (type: string = ':type', id: string = ':id') => `/explorer/${type}/${id}`,
    comparer: (type: string = ':type') => `/comparer/${type}`,
    dashboard: '/dashboard',
    export: (type: string = ':type') => `/export/${type}`,
    powerBiReport: (workspaceId: string = ':workspaceId', reportId: string = ':reportId') =>
        `/powerbi/workspaces/${workspaceId}/reports/${reportId}`,
    powerBiWorkspaces: '/powerbi/workspaces',
}
