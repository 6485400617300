import { config } from '../../../config'
import { EmbedParams, EmbedWorkspace } from './types'
import { ResponsePromise, client } from '@unicaiot/unica-iot-gallery-core'

export interface IPbiEmbedService {
    getEmbedParams: (workspaceId: string, reportId: string) => ResponsePromise<EmbedParams>
    getEmbedWorkspaces: () => ResponsePromise<EmbedWorkspace[]>
}

export const pbiEmbedService: IPbiEmbedService = {
    getEmbedParams(workspaceId: string, reportId: string) {
        return client.get<EmbedParams>(config.pbiEmbed.embedParams(workspaceId, reportId))
    },
    getEmbedWorkspaces() {
        return client.get<EmbedWorkspace[]>(config.pbiEmbed.worksapces)
    },
}
