import { config } from '../../../config'
import { Building, HData, IEntity, Kpi, PointKpi, Room, Type } from './types'
import { ResponsePromise, client } from '@unicaiot/unica-iot-gallery-core'
import dateFormat from 'dateformat'

export interface IExplorerService {
    getOverviewAllCounts: (type: Type, kpiType?: PointKpi) => ResponsePromise<Kpi>
    getOverview: (type: Type, kpiType?: PointKpi, exclude?: boolean) => ResponsePromise<Building[]>
    getBuilding: (pointRef: string) => ResponsePromise<Building>
    putEntity: (type: 'rooms' | 'buildings', entity: IEntity) => ResponsePromise
    deleteEntity: (type: 'rooms' | 'buildings', id: string) => ResponsePromise
    getRoom: (pointRef: string) => ResponsePromise<Room>
    getData: (id: string, startDate: Date, endDate: Date) => ResponsePromise<HData>
    getPredictions: (id: string) => ResponsePromise<HData>
    postExport: (ids: string[], from: Date, to: Date) => ResponsePromise<ArrayBuffer>
}

export const explorerService: IExplorerService = {
    getOverviewAllCounts(type: Type, kpiType?: PointKpi) {
        return client.get<Kpi>(config.kpi.getAllOverviewCounts, { params: { type, kpiType } })
    },
    getOverview(type: Type, kpiType?: PointKpi, exclude?: boolean) {
        return client.get<Building[]>(config.kpi.getAllOverview, { params: { type, kpiType, exclude } })
    },
    getBuilding(pointRef: string) {
        return client.get<Building>(config.buildings, { params: { pointRef } })
    },
    putEntity(type: 'rooms' | 'buildings', enity: IEntity) {
        return client.put(config.entities(type, enity.id), enity)
    },
    deleteEntity(type: 'rooms' | 'buildings', id: string) {
        return client.delete(config.entities(type, id))
    },
    getRoom(pointRef: string) {
        return client.get<Room>(config.rooms, { params: { pointRef } })
    },
    getData(id: string, startDate: Date, endDate: Date) {
        function getFormatedDate(date: Date) {
            return dateFormat(date, 'yyyy-mm-dd')
        }

        return client.get(config.hdata.get, {
            params: { id: `@${id}`, range: `"${getFormatedDate(startDate)},${getFormatedDate(endDate)}"` },
        })
    },
    getPredictions(id: string) {
        return client.get(config.hpredictions.get, {
            params: { id: `@${id}` },
        })
    },
    postExport(ids: string[], from: Date, to: Date) {
        function getFormatedDate(date: Date) {
            return dateFormat(date, 'yyyy-mm-dd')
        }

        return client.post(config.export, ids, {
            params: { from: getFormatedDate(from), to: getFormatedDate(to) },
            responseType: 'arraybuffer',
        })
    },
}
