import {
    ErrorLayout,
    FontSize,
    HeadingLevel,
    Row,
    TextColorEnumType,
    Title,
    Widget,
} from '@unicaiot/unica-iot-gallery-airinsight'
import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from 'react'
import { useGetOverviewAllCounts } from '../../services/hooks'
import { PointKpi, Type } from '../../services/types'
import legend from '../Common/Legent'
import '../../../../../node_modules/react-vis/dist/style.css'
import { ActivityIndicator, ActivityIndicatorEnumType, LocaleContext, nameof } from '@unicaiot/unica-iot-gallery-core'
import styles from './ChartWidget.module.scss'
import { translations } from '../../../../translations'
import classNames from 'classnames'

export const ChartWidget: FunctionComponent<{
    type: Type
    kpiType?: PointKpi
}> = ({ type, kpiType }) => {
    const locale = useContext(LocaleContext)
    const [data, setData] = useState<{ kpi: PointKpi; kpiName: string; count: number; color: string | undefined }[]>([])

    const result = useGetOverviewAllCounts(type, kpiType)

    useEffect(() => {
        if (result.data) {
            setData(
                Object.values(PointKpi)
                    .filter(k => k !== PointKpi.Unknown)
                    .map(k => {
                        return {
                            kpi: k,
                            kpiName: locale._(
                                `${nameof<typeof translations>('chartWidget')}.${PointKpi[k].toLowerCase()}`
                            ),
                            count: (result.data && result.data[PointKpi[k]]) || 0,
                            color: legend.get(k),
                        }
                    })
            )
        }
    }, [result.data, locale])

    return (
        <Widget className={styles.widget} borders={true}>
            {result.loading ? (
                <ActivityIndicator size={ActivityIndicatorEnumType.large} className={styles.loader} />
            ) : result.error ? (
                <ErrorLayout />
            ) : (
                result.data && (
                    <Fragment>
                        <Title className={styles.title} fontSize={FontSize.size18} headingLevel={HeadingLevel.h2}>
                            <span>{locale._(translations.chartWidget.title)}</span>{' '}
                            <span className={styles.total}>
                                {locale._(translations.chartWidget.total, {
                                    total: data.map(bar => bar.count).reduce((a, b) => a + b, 0),
                                })}
                            </span>
                        </Title>
                        <Row>
                            {data.map((bar, i) => {
                                return (
                                    <span
                                        key={`r_${i}`}
                                        className={classNames(styles.kpi, {
                                            [styles.isColorWhite]: bar.color === TextColorEnumType.white,
                                            [styles.isColorRed]: bar.color === TextColorEnumType.red,
                                            [styles.isColorYellow]: bar.color === TextColorEnumType.yellow,
                                            [styles.isColorGreen]: bar.color === TextColorEnumType.green,
                                        })}
                                    >
                                        {`${bar.count} ${bar.kpiName}`}
                                    </span>
                                )
                            })}
                        </Row>
                        <Title className={styles.subTitle} fontSize={FontSize.size14} headingLevel={HeadingLevel.h3}>
                            {locale._(translations.chartWidget.subTitle)}
                        </Title>
                    </Fragment>
                )
            )}
        </Widget>
    )
}
