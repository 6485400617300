/* eslint-disable */ 

import React, { FunctionComponent, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { service, factories, models, IEmbedConfiguration } from 'powerbi-client'

import { Auth, PageLayout } from '@unicaiot/unica-iot-gallery-airinsight'

import styles from './PowerBIEmbededReportView.module.scss'
import { useGetEmbedParams } from '../services/hooks'
import { PageError } from '@unicaiot/unica-iot-gallery-core'
import { routes } from '../../../routes'

export interface Params {
    workspaceId: string
    reportId: string
}

export const PowerBIEmbededReportView: FunctionComponent<RouteComponentProps<Params>> = params => {
    return (
        <Auth>
            <PowerBIEmbededReportLayout {...params.match.params} />
        </Auth>
    )
}

const PowerBIEmbededReportLayout: FunctionComponent<Params> = ({ workspaceId, reportId }) => {
    const [reportContainer, setReportContainer] = useState<HTMLDivElement | null>()

    const powerbi = useMemo(() => {
        return new service.Service(factories.hpmFactory, factories.wpmpFactory, factories.routerFactory)
    }, [])

    const result = useGetEmbedParams(workspaceId, reportId)

    if (result.error) {
        throw new PageError(result.error)
    }

    useEffect(() => {
        if (reportContainer && result.data) {
            const embedConfiguration: IEmbedConfiguration = {
                type: 'report',
                tokenType: models.TokenType.Embed,
                accessToken: result.data.embedToken.token,
                embedUrl: result.data.embedUrl,
                id: result.data.embedReport.id,
            }

            const report = powerbi.embed(reportContainer, embedConfiguration)

            report.off('loaded')

            report.on('loaded', function () {
                console.log('Report load successful')
            })

            report.off('rendered')

            report.on('rendered', function () {
                console.log('Report render successful')
            })

            report.off('error')

            report.on('error', function (event) {
                const errorMsg = event.detail

                console.error(errorMsg)
            })

            return () => {
                powerbi.reset(reportContainer)
            }
        }
    }, [result.data, powerbi, reportContainer])

    return (
        <PageLayout
            title={result.data?.embedReport?.name || ''}
            loading={result.loading}
            backRoute={routes.powerBiWorkspaces}
            content={() => {
                return (
                    <div ref={r => setReportContainer(r)} className={styles.reportContainer}>
                        Loading the report...
                    </div>
                )
            }}
            contentClassName={styles.content}
            showFooter={true}
            showHeader={true}
            fullScreen={true}
        />
    )
}
