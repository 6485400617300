import {
    Auth,
    LayoutType,
    DashboardLayout,
    Column,
    DeviceLayout,
    RowLayout,
} from '@unicaiot/unica-iot-gallery-airinsight'
import React, { Fragment, FunctionComponent } from 'react'

import { DataOverviewLayout } from '../components/DataOverviewLayout/DataOverviewLayout'
import { WelcomeWidget } from '../components/WecomeWidget/WelcomeWidget'
import { AnnouncmentWidget } from '../components/AnnouncmentWidget/AnnouncmentWidget'
import styles from './DashbaordView.module.scss'

export const DashbaordView: FunctionComponent = () => {
    return (
        <Auth>
            <DashboardLayout pageType={LayoutType.light} showFooter={true} showHeader={true}>
                <DeviceLayout>
                    <Fragment>
                        <RowLayout className={styles.row}>
                            <Column largeSpacing={true}>
                                <WelcomeWidget />
                            </Column>
                            <Column largeSpacing={true}>
                                <AnnouncmentWidget />
                            </Column>
                        </RowLayout>
                        <DataOverviewLayout />
                    </Fragment>
                </DeviceLayout>
            </DashboardLayout>
        </Auth>
    )
}
