import { EmbedParams, EmbedWorkspace } from './types'
import { pbiEmbedService } from './service'
import { RequestStatus, useRequest } from '@unicaiot/unica-iot-gallery-core'

export const useGetEmbedParams = (workspaceId: string, reportId: string): RequestStatus<EmbedParams | undefined> => {
    const getProsmise = () => pbiEmbedService.getEmbedParams(workspaceId, reportId)

    return useRequest(getProsmise, [workspaceId, reportId])
}

export const useGetEmbedWorkspaces = (): RequestStatus<EmbedWorkspace[] | undefined> => {
    return useRequest(pbiEmbedService.getEmbedWorkspaces, [])
}
